import React from "react";
import ImageModal from "../../../../ui/modals/ImageModal";

const UserDetail = ({ user }) => {
  console.log("here", user.disabled);
  return (
    <>
      <div class="overflow-hidden bg-white shadow sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6 bg-slate-200">
          <div className="md:flex">
            <div className="w-full h-fit font-bold text-lg">{user.name}</div>
          </div>
        </div>
        <div class="mt-3">
          <dl>
            <>
              <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">Imagen</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 md:flex">
                  {user.imgURL ? (
                    <ImageModal url={user.imgURL} name={user.name} />
                  ) : (
                    <p className="font-light italic">
                      No hay imagen disponible.
                    </p>
                  )}
                </dd>
              </div>

              <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">Correo</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {user.id}
                </dd>
              </div>

              <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">Rol</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {user.role.name}
                </dd>
              </div>

              <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">Sede</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {user.cellar?.name}
                </dd>
              </div>

              <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">Estado</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {user.disabled ? (
                    <div className="badge bg-red-500 border-none rounded text-slate-50 my-1 flex">
                      Deshabilitado
                    </div>
                  ) : (
                    <div className="badge bg-lime-500 border-none rounded text-slate-50 my-1 flex">
                      Habilitado
                    </div>
                  )}
                </dd>
              </div>
            </>
          </dl>
        </div>
      </div>
    </>
  );
};
export default UserDetail;
