import StatusField from "../../../../ui/statusUi/StatusField";
import TableTitleGoTo from "../../../../ui/table/titles/TableTitleGoTo";
import TableTitleImage from "../../../../ui/table/titles/TableTitleImage";

export const loanTableColumns = [
  {
    Header: " ",
    columns: [
      /* {
        Header: " ",
      }, */
      {
        Header: "Herramienta",
        accessor: "tool.name",
      },
      {
        Header: "Prestamista",
        accessor: "userNameCreatedBy",
      },
      {
        Header: "Prestatario",
        accessor: "userName",
      },
      {
        Header: "Fecha de préstamo",
        accessor: "creationDate",
      },
      {
        Header: "Fecha de devolución",
        accessor: "finishDate",
      },
      {
        Header: "Estado",
        accessor: "status",
        Cell: (props) => {
          const status = props.row.original.status;
          return <StatusField statusId={status} children={<p>{status}</p>} />;
        },
      },
      {
        Header: "Acción",
        accessor: "moreInfo",
        Cell: TableTitleGoTo,
        disableSortBy: true,
        disableFilters: true,
      },
    ],
  },
];
