import { gql } from "@apollo/client";

export const LISTCELLARS = gql`
  query ListCellars($companyId: String!) {
    listCellars(companyId: $companyId) {
      id
      name
      companyId
      company {
        id
        name
        creationDate
      }
    }
  }
`;
