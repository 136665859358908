import { gql } from "@apollo/client";

export const LISTPRODUCTS = gql`
  query ListProducts(
    $companyId: String!
    $cellarId: String
    $isApproved: Boolean
  ) {
    listProducts(
      companyId: $companyId
      cellarId: $cellarId
      isApproved: $isApproved
    ) {
      id
      name
      description
      reference
      companyId
      cellarId
      stock
      price
      depreciation
      sellingPrice
      creationDate
      company {
        id
        name
        creationDate
      }
      cellar {
        id
        name
        companyId
      }
      isApproved
      multimediaURL
      documentsURL {
        name
        url
      }
      imgURL
      invoice
      mark
      serial
      productServices {
        id
        description
        orderId
        serviceTypeId
        creationDate
        imgsURL {
          name
          url
        }
        cellarId
        companyId
        productId
        statusId
        cellar {
          id
          name
        }
        product {
          id
          name
        }
      }
    }
  }
`;
