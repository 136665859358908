import TableTitleGoTo from "../../../ui/table/titles/TableTitleGoTo";
import TableTitleImage from "../../../ui/table/titles/TableTitleImage";
import TableTitleText from "../../../ui/table/titles/TableTitleText";

export const userTableColumns = [
    {
      Header: ' ',
      columns: [
        /* {
          Header: " ",
        }, */
        {
          Header: "Foto",
          accessor: "imgURL",
          Cell: TableTitleImage,
          disableSortBy: true,
          disableFilters: true,
        },
        {
          Header: 'Nombre',
          accessor: 'name',
          renderComponent: "",
          Cell: TableTitleText,
        },
        {
          Header: 'Correo',
          accessor: 'id',
        },
        {
          Header: 'Rol',
          accessor: 'role.name',
        },
        {
          Header: 'Empresa',
          accessor: 'company.name',
        },
        {
          Header: 'Sede',
          accessor: 'cellar.name',
        },
        {
          Header: 'Acción',
          accessor: 'moreInfo',
          Cell: TableTitleGoTo,
          disableSortBy: true,
          disableFilters: true,
        },
      ],
    },
  ];