import React from "react";
import { useDispatch } from "react-redux";
import { UPDATEPRODUCTS } from "../../../../graphql/mutations/products/updateProduct";
import { LISTPRODUCTS } from "../../../../graphql/queries/product/listProducts";
import { ActionUpdateGuard } from "../../../../guards/actions.guard";
import {
  startInModal,
  startSelectedElement,
} from "../../../../store/tab/thunks";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import IconButton from "../../buttons/IconButton";
import successToast from "../../../../utils/toast/successToast";
import { DELETEPRODUCT } from "../../../../graphql/mutations/products/deleteProduct";
import deleteFile from "../../../../utils/deleteFile";

const TableTitleApprove = ({ cell }) => {
  const dispatch = useDispatch();

  const [updateProductMutationFunction] = useMutation(UPDATEPRODUCTS, {
    refetchQueries: [{ query: LISTPRODUCTS }, "ListProducts"],
  });

  const [deleteProductMutationFunction] = useMutation(DELETEPRODUCT, {
    refetchQueries: [{ query: LISTPRODUCTS }, "ListProducts"],
  });

  const onClickIconButton = async (isApproved) => {
    /* console.log("row", cell.row.original); */

    const product = cell.row.original;
    //  product.isApproved = true;

    if (isApproved) {
      await updateProductMutationFunction({
        variables: {
          product: {
            companyId: product.companyId,
            name: product.name,
            cellarId: product.cellarId,
            isApproved: true,
          },
          id: product.id,
        },
      })
        .then(() => {
          toast.success("Máquina aprobada", successToast);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      await deleteProductMutationFunction({
        variables: {
          companyId: product.companyId,
          id: product.id,
        },
      })
        .then(async () => {
          toast.success("Máquina eliminada", successToast);
          if (product.imgURL) {
            const fixedURL = decodeURI(
              product.imgURL.replace("https://az-icon.s3.amazonaws.com/", "")
            );
            await deleteFile(fixedURL);
          }
          if (product.documentsURL && product.documentsURL.length > 0) {
            product.documentsURL.map(async (item) => {
              const fixedURL = decodeURI(
                item.url.replace("https://az-icon.s3.amazonaws.com/", "")
              );
              /* console.log("fixedURL", fixedURL); */
              await deleteFile(fixedURL);
            });
          }
        })
        .catch((error) => {
          /* console.log(error); */
        });
    }
  };

  const onClickIconItem = (action) => {
    dispatch(startSelectedElement({ row: cell.row.original }));
    dispatch(startInModal({ inModal: action }));
  };

  return (
    <>
      <IconButton
        icon="visibility"
        tooltip="Ver detalles"
        size="xs"
        onClickIconButton={() => {
          onClickIconItem("view");
        }}
      />

      <IconButton
        icon="check"
        tooltip="Aprobar"
        size="xs"
        onClickIconButton={() => {
          onClickIconButton(true);
        }}
      />
      <ActionUpdateGuard
        children={
          <IconButton
            icon="cancel"
            tooltip="Borrar"
            size="xs"
            onClickIconButton={() => {
              onClickIconButton(false);
            }}
          />
        }
      />
    </>
  );
};

export default TableTitleApprove;
