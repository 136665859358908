import React from "react";

const TextInput = ({
  valueDisabled,
  title,
  type = "text",
  value,
  onChangeValue,
  onSelectFocus,
  error,
  isValidated,
  maxDate,
}) => {
  const today = maxDate ? new Date().toISOString().split("T")[0] : null;

  return (
    <div className=" w-full h-fit">
      <p className="text-sm font-medium">{title}</p>
      <input
        value={value}
        onChange={onChangeValue}
        onFocus={onSelectFocus}
        type={type}
        placeholder={title}
        className={`input input-bordered w-full max-w-xs ${
          isValidated != null ? (isValidated ? "" : "input-error") : ""
        }`}
        disabled={valueDisabled}
        min={maxDate ? today : null}
      />
      {isValidated != null && !isValidated && (
        <label className="label">
          <span className="label-text-alt">{error}</span>
        </label>
      )}
    </div>
  );
};

export default TextInput;
