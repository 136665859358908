import checkEmail from "../../../../../utils/checkEmail";
import checkLength from "../../../../../utils/checkLength";
import checkPass from "../../../../../utils/checkPass";

export default function checkUserFields(user, validations, options) {

    const resp = {
        valid: true,
        toastText: 'ERROR: Verifica los campos ingresados',
        toastInfo: {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        }
    };

    validations.name = checkLength(user.name);
    validations.email = checkEmail(user.id);
    validations.password = checkPass(user.password);

    if(options){
        if(options.email){
            validations.email = true;
        }
        if (options.password) {
            validations.password = true;
        }
    }


    if(!validations.name || !validations.email || !validations.password){
        resp.valid = false;
    }

    resp.newValidations = validations;

    return resp;


}