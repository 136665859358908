import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  startInModal,
} from "../../../../../store/tab/thunks";
import { useMutation } from "@apollo/client";
import IconTextButton from "../../../../ui/buttons/IconTextButton";
import ModalTemplate from "../../../../ui/modals/ModalTemplate";
import successToast from "../../../../../utils/toast/successToast";
import { toast } from "react-toastify";
import uploadFile from "../../../../../utils/uploadFile";
import { deleteTypename } from "../../../../../utils/deleteTypename";
import errorToast from "../../../../../utils/toast/errorToast";
import ToolForm from "../layout/ToolForm";
import checkToolFields from "../helpers/checkToolFields";
import TOOL_VALIDATIONS from "../helpers/validations";
import { LISTTOOLS } from "../../../../../graphql/queries/tool/listTools";
import { UPDATETOOLS } from "../../../../../graphql/mutations/tool/updateTool";

function EditTool() {
  const dispatch = useDispatch();
  const { selectedElement } = useSelector((state) => state.tab);
  const [tool, setTool] = useState(selectedElement.row);
  
  const [validations, setValidations] = useState(TOOL_VALIDATIONS);

  const [updateProductMutationFunction, { loading }] =
    useMutation(UPDATETOOLS, {
      refetchQueries: [{ query: LISTTOOLS }, "ListTools"],
    });

  const onClickEdit = async () => {
    const { valid, toastText, toastInfo, newValidations } =
      checkToolFields(tool, validations);

    if (!valid) {
      toast.error(toastText, toastInfo);
      setValidations({ ...newValidations });
    } else {
      //Adición de imagen
      if (tool.imgBlob != null) {
        const url = await uploadFile(
          `tools/${tool.name}`,
          tool.imgBlob,
          tool.imageType
        );
        
        if (url && url.Location) {
          tool.imgURL = url.Location;
          setTool({ ...tool, imgURL: url.Location });
        }
      }

      //Adición de documento
      /* Revisar cuando se agreguen más documentos */
      if (tool.documentsBlob != null) {
        const item = await Promise.all(
          tool.documentsURL.map(async (doc, index) => {
            return {
              doc,
              result: await uploadFile(
                `tools/${doc.name}`,
                tool.documentsBlob[index],
                tool.documentsType[index]
              ),
            };
          })
        );

        tool.documentsURL = [];

        item.map((item) => {
          if (item && item.result) {
            var res = { name: item.doc.name, url: item.result.Location };
            tool.documentsURL.push(res);
          }
        });

        setTool({ ...tool });
      }
      editTool();
    }
  };

  const editTool = async () => {
    const documentsURL = tool.documentsURL.map((doc) => {
      return deleteTypename(doc);
    });

    const {
      cellar,
      company,
      imgBlob,
      documentsBlob,
      imageType,
      documentsType,
      id,
      creationDate,
      productServices,
      ...propsTool
    } = tool;

    const newpropsTool = deleteTypename(propsTool);
    newpropsTool.documentsURL = documentsURL;

    console.log("props", newpropsTool)

    await updateProductMutationFunction({
      variables: {
        tool: newpropsTool,
        id: selectedElement.row.id,
      },
    })
      .then(() => {
        toast.success("Herramienta actualizada", successToast);
        dispatch(startInModal({ inModal: null }));
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.toString(), errorToast);
      });
  };

  return (
    <>
      <ModalTemplate
        title={"Editar herramienta"}
        onClickBack={() => {
          dispatch(startInModal({ inModal: null }));
        }}
      >
        <>
          <ToolForm
            tool={tool}
            setTool={setTool}
            name={"Herramienta"}
            validations={validations}
            setValidations={setValidations}
          />

          <div className="my-4 bg-white place-content-end flex p-4 rounded-lg shadow-sm">
            <IconTextButton
              icon="done"
              text="Guardar"
              onClickButton={onClickEdit}
              loading={loading}
            />
          </div>
        </>
      </ModalTemplate>
    </>
  );
}

export default EditTool;
