import isValidDate from "../../../../../utils/checkDateRange";
import checkLength from "../../../../../utils/checkLength";

export default function checkLoanFields(loan, validations) {
  const resp = {
    valid: true,
    toastText: "ERROR: Verifica los campos ingresados",
    toastInfo: {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    },
  };

  /* validations.name = checkLength(loan.name); */
  validations.description = checkLength(loan.description);
  validations.emails = checkLength(loan.emails);
  validations.createdBy = checkLength(loan.createdBy);
  validations.userName = checkLength(loan.userName);
  validations.userNameCreatedBy = checkLength(loan.userNameCreatedBy);
  validations.creationDate = isValidDate(loan.creationDate);
  validations.finishDate = isValidDate(loan.finishDate);

  if (
    /* !validations.name || */
    !validations.description ||
    !validations.createdBy ||
    !validations.userName ||
    !validations.userNameCreatedBy ||
    !validations.creationDate ||
    !validations.finishDate
  ) {
    resp.valid = false;
  }

  if (!validations.emails) {
    resp.toastText = "ERROR: Debes ingresar al menos un correo";
  }

  resp.newValidations = validations;

  return resp;
}
