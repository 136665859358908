import { gql } from "@apollo/client";

export const UPDATEUSERS = gql`
  mutation UpdateUsers($user: UpdateUserInput!) {
    updateUser(user: $user) {
      companies
      creationDate
      disabled
      id
      imgURL
      name
      phone_number
      roleId
      companyId
      cellarId
    }
  }
`;
