import React from "react";

const ColoredLine = ({ color }) => {
  return (
    <hr
      style={{
        color: color,
        opacity: 0.2,
        backgroundColor: color,
        height: 1,
      }}
    />
  );
};

export default ColoredLine;
