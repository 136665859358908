import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { startComponent, startDataCRUD } from "../../../../../store/tab/thunks";
import { useState } from "react";
import Loading from "../../../../ui/loading/Loading";
import { useQuery } from "@apollo/client";
import TabLayout from "../../shared/TabLayout";
import { useMemo } from "react";
import { loanTableColumns } from "./loanTableColumns";
import AddLoan from "../pages/AddLoan";
import EditLoan from "../pages/EditLoan";
import ViewLoan from "../pages/ViewLoan";
import { LISTLOANS } from "../../../../../graphql/queries/loan/listLoans";

export const Loan = () => {
  const dispatch = useDispatch();
  const { company, cellarId } = useSelector((state) => state.auth);
  const { dataResponse } = useSelector((state) => state.tab);

  const { data, loading, error } = useQuery(LISTLOANS, {
    variables: { companyId: company.id, cellarId: cellarId },
    onCompleted: (data) => {
      dispatch(
        startDataCRUD({
          dataResponse: data.listLoans,
        })
      );
    },
  });

  console.log("loans", data, error);

  dispatch(startComponent({ component: "Loan" }));
  //if (loading) return <Loading />;
  const columns = useMemo(() => loanTableColumns, []);

  return (
    <div>
      <>
        <TabLayout
          titleComponent={"Préstamos"}
          AddElement={AddLoan}
          EditElement={EditLoan}
          ViewElement={ViewLoan}
          nameElement={"préstamo"}
          data={data}
          dataQuery={dataResponse}
          columns={columns}
          // fetchData={fetchData}
          /* loading={loading} */
          // pageCount={pageCount}
          hasDownloadBtn={true}
        />
      </>
    </div>
  );
};
