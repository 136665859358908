import React from "react";
const IconButton = ({ icon, tooltip, size, ghost, onClickIconButton }) => {

  return (
    <>
      {tooltip && (
        <div className="tooltip" data-tip={tooltip}>
          <button
            className={` btn btn-outline  ${size ? "btn-" + size : ""}`}
            onClick={onClickIconButton}
          >
            <span className="material-symbols-outlined">{icon}</span>
          </button>
        </div>
      )}
      {!tooltip && (
        <button
          className={` btn ${ghost ? "btn-ghost" : "btn-outline"}  ${
            size ? "btn-" + size : ""
          }`}
          onClick={onClickIconButton}
        >
          <span className="material-symbols-outlined">{icon}</span>
        </button>
      )}
    </>
  );
};

export default IconButton;
