import Resizer from "react-image-file-resizer";

export const resizeFile = (file, { maxWidth, maxHeight }) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth ? maxWidth : 512,
      maxHeight ? maxHeight : 512,
      "WEBP",
      90,
      0,
      (uri) => {
        /* console.log("uri", uri); */
        resolve(uri);
      },
      "blob"
      // 488
    );
  });
};
