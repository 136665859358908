import React from 'react'
import { Route, Routes } from 'react-router-dom';
import NotFound from './NotFound';

export default function RoutesWithNotFound({ children }) {
  return (
    <Routes>
        { children }
        <Route path="*" element={<NotFound/>} />
    </Routes>
  );
}
