import { gql } from "@apollo/client";

export const LISTORDERS = gql`
  query ListOrders($companyId: String!, $cellarId: String) {
    listOrders(companyId: $companyId, cellarId: $cellarId) {
      id
      name
      description
      creationDate
      companyId
      services {
        id
        orderId
        description
        serviceTypeId
        creationDate
        emails
        date
        imgsURL {
          name
          url
        }
        cellarId
        productId
        statusId
        cellar {
          id
          name
        }
        logs
        product {
          id
          name
        }
      }
    }
  }
`;
