import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Login($userId: String!, $password: String!) {
    login(userId: $userId, password: $password) {
      id
      name
      phone_number
      companies
      disabled
      config {
        appVersion
      }
      companyId
      company {
        id
        name
        creationDate
      }
      roleId
      role {
        id
        name
      }
      creationDate
      imgURL
      cellarId
    }
  }
`;
