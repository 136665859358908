import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../buttons/Icon";
import { logout } from "../../../store/auth/authSlice";
import { PublicRoutes } from "../../../router/routes";
import { themeStyle } from "../../../styles";

export const Navbar = ({ toggleSidebar }) => {
  const { name, imgURL, role } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLogout = () => {
    dispatch(logout());
    navigate(PublicRoutes.LOGIN, {
      replace: true,
    });
  };

  return (
    <div className="navbar px-10 z-20 bg-dark text-textLight">
      <div className="flex-1">
        <button onClick={toggleSidebar} className="sidebar-toggle">
          <Icon icon="menu" />
        </button>
        {/*<p className="normal-case text-xl pl-20">AZLOGICA</p>*/}
      </div>

      <div className="flex-1">
        <p className="normal-case text-xl">
          <img
            className="w-10"
            src={themeStyle.logoLight}
            alt="Logo de inventory control"
          />
        </p>
      </div>

      <div className="flex-none gap-2">
        <p className="hidden md:block">{name}</p>
        <div className="dropdown dropdown-end">
          <label tabIndex="0" className="btn btn-ghost btn-circle avatar">
            <div className="w-10 rounded-full flex align-middle justify-center">
              {imgURL ? (
                <img src={imgURL} alt="Imagen de perfil" />
              ) : (
                <img src="/images/icons/user.png" alt="Imagen de perfil" />
              )}
            </div>
          </label>
          <ul
            tabIndex="0"
            className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52 text-textDark"
          >
            <li>
              <p className="active:bg-amber-700">Bienvenido, {name}</p>
            </li>
            <li>
              <p className="active:bg-amber-700">Rol: {role.name} </p>
            </li>
            {/*
            <li>
              <button className="justify-between active:bg-amber-700">
                Editar perfil
              </button>
            </li>

            <li>
              <button className="active:bg-amber-700">Cambiar de moneda</button>
            </li>
            <li>
              <button className="active:bg-amber-700">Cambiar de idioma</button>
            </li>
            */}

            <li onClick={onLogout}>
              <button className="active:bg-amber-700">
                <Icon icon="logout" /> Cerrar sesión
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
