import React from "react";
import { PrivateRoutes } from "../../../router/routes";
import { ActionViewItemOnSidebarGuard } from "../../../guards/actions.guard";
import { useLocation } from "react-router-dom";

export const Sidebar = ({ isOpen }) => {
  const sidebarClass = isOpen ? "sidebar open" : "sidebar hidden";
  const location = useLocation();
  return (
    <div
      className={`${
        isOpen ? "md:w-48 w-full" : ""
      } duration-300 md:h-[92vh] lg:h-[92vh] bg-primary text-textPrimary shadow-2xl`}
    >
      <div>
        <div className={`${sidebarClass} z-20  overflow-y-auto text-center`}>
          <div className="text-gray-100 text-xl">
            <div className="p-2.5 mt-1 flex items-center">
              <div className="font-bold text-gray-600 text-2xl ml-3">
                Menú
              </div>
            </div>
          </div>

          <div className="my-2 bg-gray-300 h-[1px]"></div>

          <div
            className="text-left text-sm mt-2 w-full mx-auto text-gray-600 font-bold"
            id="submenu"
          >
            <div className="my-5">
              <ActionViewItemOnSidebarGuard
                route={`${PrivateRoutes.ADMIN}/${PrivateRoutes.USER}`}
                isActive={
                  location.pathname.indexOf(
                    `/home/${PrivateRoutes.ADMIN}/${PrivateRoutes.USER}`
                  ) >= 0
                    ? true
                    : false
                }
                icon={"usuario"}
                name={"Usuarios"}
                componentName={"User"}
              />
            </div>

            <div className="my-5">
              {
                <ActionViewItemOnSidebarGuard
                  route={PrivateRoutes.PRODUCT}
                  isActive={
                    location.pathname.indexOf(
                      `/home/${PrivateRoutes.PRODUCT}`
                    ) >= 0
                      ? true
                      : false
                  }
                  icon={"maquinaria"}
                  name={"Maquinaria"}
                  componentName={"Product"}
                />
              }
            </div>

            <div className="my-5">
              {
                <ActionViewItemOnSidebarGuard
                  route={PrivateRoutes.PRODUCT_APPROVED}
                  isActive={
                    location.pathname.indexOf(
                      `/home/${PrivateRoutes.PRODUCT_APPROVED}`
                    ) >= 0
                      ? true
                      : false
                  }
                  icon={"aprobar"}
                  name={"Aprobar Maquinaria"}
                  componentName={"ProductApproved"}
                />
              }
            </div>

            <div className="my-5">
              <ActionViewItemOnSidebarGuard
                route={PrivateRoutes.REQUEST}
                isActive={
                  location.pathname.indexOf(`/home/${PrivateRoutes.REQUEST}`) >=
                  0
                    ? true
                    : false
                }
                icon={"solicitudes"}
                name={"Solicitudes"}
                componentName={"Request"}
              />
            </div>

            <div className="my-5">
              <ActionViewItemOnSidebarGuard
                route={PrivateRoutes.ZONE}
                isActive={
                  location.pathname.indexOf(`/home/${PrivateRoutes.ZONE}`) >= 0
                    ? true
                    : false
                }
                icon={"sedes"}
                name={"Sede"}
                componentName={"Zone"}
              />
            </div>
            
            <div className="my-5">
              {
                <ActionViewItemOnSidebarGuard
                  route={PrivateRoutes.TOOL}
                  isActive={
                    location.pathname.indexOf(
                      `/home/${PrivateRoutes.TOOL}`
                    ) >= 0
                      ? true
                      : false
                  }
                  icon={"herramientas"}
                  name={"Herramientas"}
                  componentName={"Tool"}
                />
              }
            </div>

            <div className="my-5">
              <ActionViewItemOnSidebarGuard
                route={PrivateRoutes.LOAN}
                isActive={
                  location.pathname.indexOf(`/home/${PrivateRoutes.LOAN}`) >= 0
                    ? true
                    : false
                }
                icon={"loan"}
                name={"Préstamos"}
                componentName={"Loan"}
              />
            </div>

            <div className="my-5">
              <ActionViewItemOnSidebarGuard
                route={PrivateRoutes.UPLOAD}
                isActive={
                  location.pathname.indexOf(`/home/${PrivateRoutes.UPLOAD}`) >= 0
                    ? true
                    : false
                }
                icon={"subir"}
                name={"Subir archivo maquinaria"}
                componentName={"Upload"}
              />
            </div>

            <div className="my-5">
              <ActionViewItemOnSidebarGuard
                route={PrivateRoutes.UPLOADTOOLS}
                isActive={
                  location.pathname.indexOf(`/home/${PrivateRoutes.UPLOADTOOLS}`) >= 0
                    ? true
                    : false
                }
                icon={"subir"}
                name={"Subir archivo herramientas"}
                componentName={"UploadTools"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
