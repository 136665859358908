import React from "react";
import Moment from "react-moment";
import "moment-timezone";
import StatusField from "../../../../ui/statusUi/StatusField";

const HistoricService = ({ product }) => {
  return (
    <div className="collapse collapse-arrow">
      <input type="checkbox" className="peer" />

      <div className="collapse-title bg-slate-200  peer-checked:text-secondary-content">
        <h3 class="text-md font-medium leading-6 text-slate-700">
          <div>Historial de servicios </div>
        </h3>
      </div>
      <div className="collapse-content text-primary-content peer-checked:text-secondary-content mt-3">
        <div class="overflow-x-auto">
          <table class="table table-compact w-full">
            <thead>
              <tr>
                <th>N° solicitud</th>
                <th>Asunto</th>
                <th>Descripción</th>
                <th>Fecha de solicitud</th>
                <th>Sede</th>
                <th>Máquina</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {product && product.productServices && product.productServices.map((item) => (
                <tr>
                  <td>{item.id}</td>
                  <td>{product.name}</td>
                  <td>{item.description}</td>
                  <td>
                    <Moment locale="co" format="YYYY-MM-DD hh:mm">
                      {new Date(item.creationDate)}
                    </Moment>
                  </td>
                  <td>{product.cellar.name}</td>
                  <td>{product.company.name}</td>
                  <td>
                    <StatusField
                      statusId={item.statusId}
                      children={<p>{item.statusId}</p>}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default HistoricService;
