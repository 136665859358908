import React from 'react'

function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {

  
    return (
      <input
      className="input input-bordered w-full max-w-xs input-sm text-sm font-normal"
      onClick={(e) => {e.stopPropagation()}}
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Buscar...`}
      />
    )
  }

export default DefaultColumnFilter; 