import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import client from "./graphql/client";
import { store } from "./store/store";
import "./index.css";
import "material-symbols";
import { AppRouter } from "./router/AppRouter";
import "react-toastify/dist/ReactToastify.css";
/*
 * Import the SDK and Project Configuration
 */
import AWS from "aws-sdk";
import { ToastContainer } from "react-toastify";
/*
 * Configure the SDK to use anonymous identity
 */
AWS.config.update({
  region: process.env.REACT_APP_REGION,
  credentials: new AWS.Credentials({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  }),
});

if (process.env.REACT_APP_ENV === "dev") {
  console.log("Modo de desarrollo.");
} else {
  console.log = function () {};
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ToastContainer />
        <ApolloProvider client={client}>
          <AppRouter />
        </ApolloProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
