import { useState } from "react";
import { useMutation } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import { FORGOTPASSWORD } from "../../../graphql/mutations/auth/forgotPassword";
import { PublicRoutes } from "../../../router/routes";
import Icon from "../../ui/buttons/Icon";
import { ScreenForm } from "../layout/ScreenForm";
import errorToast from "../../../utils/toast/errorToast";
import successToast from "../../../utils/toast/successToast";
import { toast } from "react-toastify";
import Loading from "../../ui/loading/Loading";
import { EMAIL_ERROR } from "../../admin/pages/users/helpers/inputErrors";
import checkEmail from "../../../utils/checkEmail";
import TextInput from "../../ui/Inputs/TextInput";
import EMAIL_VALIDATION from "../../admin/pages/users/helpers/emailValidation";
import checkEmailField from "../../admin/pages/users/helpers/checkEmailField";

export const RecoveryPassword = () => {
  const navigate = useNavigate();
  const [forgotPasswordMutationFunction, { loading }] =
    useMutation(FORGOTPASSWORD);
  const [email, setEmail] = useState({
    email: "",
  });

  const [validations, setValidations] = useState(EMAIL_VALIDATION);

  const onClickRecovery = async () => {
    const { valid, toastText, toastInfo, newValidations } = checkEmailField(
      email,
      validations
    );

    if (!valid) {
      toast.error(toastText, toastInfo);
      setValidations({ ...newValidations });
    } else {
      await forgotPasswordMutationFunction({
        variables: {
          userId: email.email,
        },
      })
        .then((data) => {
          /* console.log(data); */
          toast.success(
            "Se ha enviado un código de recuperación a su correo.",
            successToast
          );
          navigate(PublicRoutes.INSERT_CODE, {
            replace: true,
            state: { email: { email } },
          });
        })
        .catch((error) => {
          /* console.log(error); */
          toast.error(
            "No se ha podido realizar el envío del código de recuperación.",
            errorToast
          );
        });
    }
  };

  if (loading) return <Loading />;

  return (
    <ScreenForm>
      <div className="mt-8">
        <div>
          <TextInput
            title={"Correo"}
            value={email.email}
            onChangeValue={(e) => {
              setEmail({ email: e.target.value });
              setValidations({
                ...validations,
                email: checkEmail(e.target.value),
              });
            }}
            error={EMAIL_ERROR}
            isValidated={validations.email}
          />
        </div>
      </div>

      <button
        onClick={onClickRecovery}
        className={`cursor-pointer w-full mb-2 active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 mt-4 rounded-xl flex justify-center bg-primary text-white text-md font-bold`}
      >
        <div>Recuperar</div>
      </button>

      <Link
        className="flex items-center font-bold text-amber-700"
        to={PublicRoutes.LOGIN}
      >
        <Icon icon="arrow_back" /> Regresar al inicio de sesión
      </Link>
    </ScreenForm>
  );
};
