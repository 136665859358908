import checkLengthCode from "../../../../../utils/checkLength";
import checkPass from "../../../../../utils/checkPass";

export default function checkUserRecoveryFields(user, validations, options) {

    const resp = {
        valid: true,
        toastText: 'ERROR: Verifica los campos ingresados',
        toastInfo: {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        }
    };

    validations.verificationCode = checkLengthCode(user.verificationCode);
    validations.password = checkPass(user.password);

    if(options){
        if(options.verificationCode){
            validations.verificationCode = true;
        }
        if (options.password) {
            validations.password = true;
        }
    }


    if(!validations.verificationCode || !validations.password){
        resp.valid = false;
    }

    resp.newValidations = validations;

    return resp;


}