import React from "react";

const ImageModal = ({ url, name, index, indexService }) => {
  return (
    <>
      <label
        htmlFor={
          index || indexService
            ? `modal-img${indexService}${index}`
            : `modal-img`
        }
        className="cursor-pointer tooltip"
        data-tip="Ver imagen"
      >
        <img
          src={url}
          className="m-2 w-20"
          alt="Imagen relacionada al servicio"
        />
      </label>

      <input
        type="checkbox"
        id={
          index || indexService
            ? `modal-img${indexService}${index}`
            : `modal-img`
        }
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">{name}</h3>
          <div className="flex justify-center align-middle">
            <img src={url} className="m-2 w-34" alt="Imagen" />
          </div>

          <div className="modal-action">
            <label
              htmlFor={
                index || indexService
                  ? `modal-img${indexService}${index}`
                  : `modal-img`
              }
              className="btn"
            >
              Cerrar
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageModal;
