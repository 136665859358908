import React from "react";
import { useDispatch } from "react-redux";
import { ActionUpdateGuard } from "../../../../guards/actions.guard";
import {
  startInModal,
  startSelectedElement,
} from "../../../../store/tab/thunks";

import IconButton from "../../buttons/IconButton";

const TableTitleGoTo = ({ cell }) => {
  const dispatch = useDispatch();

  const onClickIconButton = (action) => {
    dispatch(startSelectedElement({ row: cell.row.original }));
    dispatch(startInModal({ inModal: action }));
  };

  return (
    <>
      <IconButton
        icon="visibility"
        tooltip="Ver detalles"
        size="xs"
        onClickIconButton={() => {
          onClickIconButton("view");
        }}
      />
      <ActionUpdateGuard
        children={
          <IconButton
            icon="edit"
            tooltip="Editar"
            size="xs"
            onClickIconButton={() => {
              onClickIconButton("edit");
            }}
          />
        }
      />
    </>
  );
};

export default TableTitleGoTo;
