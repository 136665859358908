import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionUpdateGuard } from "../../../../../guards/actions.guard";
import { startInModal } from "../../../../../store/tab/thunks";
import IconTextButton from "../../../../ui/buttons/IconTextButton";
import ModalTemplate from "../../../../ui/modals/ModalTemplate";


import LoanDetail from "../layout/LoanDetail";
const ViewLoan = ({ hideActionButtons = false }) => {
  const dispatch = useDispatch();
  const { selectedElement } = useSelector((state) => state.tab);
  const loan = selectedElement.row;

  return (
    <>
      <ModalTemplate
        title={"Ver detalles"}
        onClickBack={() => {
          dispatch(startInModal({ inModal: null }));
        }}
      >
        <>
          <LoanDetail loan={loan} name={"préstamo"} />

          {!hideActionButtons && (
            <div className="my-4 bg-white place-content-end flex p-4 rounded-lg shadow-sm">
              <ActionUpdateGuard
                children={
                  <IconTextButton
                    icon="edit"
                    text="Editar"
                    onClickButton={() => {
                      dispatch(startInModal({ inModal: "edit" }));
                    }}
                  />
                }
              />
            </div>
          )}
        </>
      </ModalTemplate>
    </>
  );
};

export default ViewLoan;
