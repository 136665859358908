import React from 'react'

const IconTextButton = ({ icon, tooltip, size, text, onClickButton, loading, valueDisabled }) => {
  return (
    <div
      className='w-fit h-max'
    
    >
      {tooltip &&
        <div className="tooltip" data-tip={tooltip}>
          <button
            onClick={onClickButton}
            disabled={loading}
            className={`btn bg-dark border-none h-max py-2 shadow-md rounded-lg ${size ? 'btn-' + size : ''} `}>

            {!loading && <span className="material-symbols-outlined">{icon}</span>}
            {loading && <svg className="animate-spin h-5 w-5 mr-3 bg-slate-800  " viewBox="0 0 24 24"> </svg>}
            <div>{text}</div>
          </button>
        </div>
      }
      {
        !tooltip &&
        <button
          onClick={onClickButton}
          disabled={loading}
          className={`btn bg-dark-800 border-none h-max py-2 shadow-md rounded-lg ${size ? 'btn-' + size : ''} `}>

          {!loading && <span className="material-symbols-outlined">{icon}</span>}
          {loading && <svg className="animate-spin h-5 w-5 mr-3 bg-slate-800  " viewBox="0 0 24 24"> </svg>}

          <div>{text}</div>
        </button>
      }
    </div>
  )
}

export default IconTextButton