import React from "react";
const SelectOption = ({ disabled, onChangeDisabled }) => {
  return (
    <>
      <select
        className="select w-4/5 "
        onChange={(e) => {
          onChangeDisabled(e.target.value);
        }}
        value={disabled}
      >
        <option disabled selected>
            Selecciona una opción
          </option>
        <option className="display-inline" key={"true"} value={"true"}>
          Deshabilitar usuario
        </option>

        <option className="display-inline" key={"false"} value={"false"}>
          Habilitar usuario
        </option>
      </select>
    </>
  );
};

export default SelectOption;
