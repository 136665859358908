import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { LISTUSERS } from "../graphql/queries/user/listUsers";
import { PrivateRoutes, PublicRoutes } from "../router/routes";
import { logout } from "../store/auth/authSlice";

export const AuthGuard = ({ privateValidation }) => {
  const dispatch = useDispatch();
  const { id, company } = useSelector((state) => state.auth);
  const { status, disabled } = useSelector((state) => state.auth);

  /*   console.log("Deshabilitado del auth guard", id);

  const { data, loading } = useQuery(LISTUSERS, {
    variables: { companyId: company.id },
  });

  console.log(data)
  let newDataUser = []

  data.listUsers.map((usuario) => {
    if (usuario.id === id) {
      
newDataUser.push({...usuario})
    }
  });
 */
  /* return status === "authenticated" && disabled !== true ? (
    privateValidation ? (
      <Outlet />
    ) : (
      <Navigate replace to={PrivateRoutes.HOME} />
    )
  ) : (
    //Dispatch logout para prevenir fallo seguridad
    <Navigate replace to={PublicRoutes.LOGIN} />
  ); */

  return status === "authenticated" ? (
    privateValidation ? (
      <Outlet />
    ) : (
      <Navigate replace to={PrivateRoutes.HOME} />
    )
  ) : (
    (() => {
      dispatch(logout());
      return <Navigate replace to={PublicRoutes.LOGIN} />;
    })()
  );
  
};

export default AuthGuard;
