import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";

const InputFileUpload = ({ children, id, onChangeFile }) => {
  const [isDragging, setDragging] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);

    const file = e.dataTransfer.files[0];

    onChangeFile(file);
  };

  return (
    <>
      <div
        className={`${
          isDragging ? "border-blue-500 bg-blue-100" : ""
        } border-dashed border-2 rounded-md p-4 w-[50%]`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <input
          accept=".csv"
          type="file"
          id={id ? id : "id"}
          style={{ display: "none" }}
          onChange={(e) => onChangeFile(e.target.files[0])}
        />        
        <label className="flex justify-center" htmlFor={id ? id : "id"}>{children}</label>

      </div>
    </>
  );
};

export default InputFileUpload;