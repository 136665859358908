import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionUpdateGuard } from "../../../../../guards/actions.guard";
import { startInModal } from "../../../../../store/tab/thunks";
import IconTextButton from "../../../../ui/buttons/IconTextButton";
import ModalTemplate from "../../../../ui/modals/ModalTemplate";

import { PDFDownloadLink } from "@react-pdf/renderer";
import { ExportProductPDF } from "../../../../exports/ExportProductPDF";
import ProductDetail from "../layout/ProductDetail";

const ViewProduct = ({ hideActionButtons = false }) => {
  const dispatch = useDispatch();
  const { selectedElement } = useSelector((state) => state.tab);
  const product = selectedElement.row;

  return (
    <>
      <ModalTemplate
        title={"Ver detalles"}
        onClickBack={() => {
          dispatch(startInModal({ inModal: null }));
        }}
      >
        <>
          <ProductDetail product={product} name={"maquinaria"} />

          {!hideActionButtons && (
            <div className="my-4 bg-white place-content-end flex p-4 rounded-lg shadow-sm">
              <div className="px-4">
                <button
                  className={`btn bg-slate-800 border-none  shadow-md rounded-lg `}
                >
                  <PDFDownloadLink
                    document={<ExportProductPDF product={product} />}
                    fileName="maquinaria.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? "CARGANDO..." : "DESCARGAR"
                    }
                  </PDFDownloadLink>
                </button>

                {/* <ExportProductPDF product={product} />*/}
              </div>
              <ActionUpdateGuard
                children={
                  <IconTextButton
                    icon="edit"
                    text="Editar"
                    onClickButton={() => {
                      dispatch(startInModal({ inModal: "edit" }));
                    }}
                  />
                }
              />
            </div>
          )}
        </>
      </ModalTemplate>
    </>
  );
};

export default ViewProduct;
