import checkEmail from "../../../../../utils/checkEmail";

export default function checkEmailField(user, validations, options) {

    const resp = {
        valid: true,
        toastText: 'ERROR: Verifica los campos ingresados',
        toastInfo: {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        }
    };

    validations.email = checkEmail(user.email);

    if(options){
        if(options.email){
            validations.email = true;
        }
    }


    if(!validations.email){
        resp.valid = false;
    }

    resp.newValidations = validations;

    return resp;


}