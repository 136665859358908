import { createSlice } from "@reduxjs/toolkit";

export const tabSlice = createSlice({
  name: "tab",
  initialState: {
    selectedElement: null,
    inModal: null,
  },
  reducers: {
    setSelectedElement: (state, { payload }) => {
      state.selectedElement = payload;
      /* console.log(payload) */
    },
    setInModal: (state, { payload }) => {
      state.inModal = payload.inModal;
      /* console.log(payload) */
    },
    setComponent: (state, { payload }) => {
      state.component = payload.component;
      /* console.log(payload) */
    },
    setDataCRUD: (state, { payload }) => {
      state.dataResponse = payload.dataResponse;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setSelectedElement, setInModal, setComponent, setDataCRUD } = tabSlice.actions;
