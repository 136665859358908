import React from "react";
import StatusField from "../../../../ui/statusUi/StatusField";
import Moment from "react-moment";
import moment from "moment";

const LoanDetail = ({ loan }) => {
  let now = moment();
  console.log(now.format("YYYY-MM-DD"));

  console.log(loan.finishDate);

  if (now.isAfter(loan.finishDate)) {
    console.log("Sobrepasó la fecha de entrega");
  } else {
    console.log("Sigue vigente el tiempo de entrega.");
  }

  console.log("LOANNN")
  return (
    <>
      <div class="overflow-hidden bg-white shadow sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6 bg-slate-200">
          {now.isAfter(loan.finishDate) && loan.status === "EN PROCESO" && (
            <div
              role="alert"
              className="rounded border-s-4 border-red-500 bg-red-50 p-4"
            >
              <div className="flex items-center gap-2 text-red-800">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                    clipRule="evenodd"
                  />
                </svg>

                <strong className="block font-medium">
                  Se ha excedido el plazo de entrega de la herramienta
                </strong>
              </div>
            </div>
          )}
        </div>

        <div class="mt-3">
          <dl>
            <div className="text-primary-content peer-checked:text-secondary-content">
              <div className="flex w-full">
                <div className="w-4/6">
                  <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Descripción
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {loan.description}
                    </dd>
                  </div>

                  <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Herramienta
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {loan.tool.name}
                    </dd>
                  </div>

                  <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Creado por
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {loan.createdBy}
                    </dd>
                  </div>

                  <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">Correos</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {loan.emails ? (
                        loan.emails.map((email) => (
                          <div className="badge bg-slate-500 border-none rounded text-slate-50 my-1 flex">
                            <p>{email}</p>
                          </div>
                        ))
                      ) : (
                        <p className="font-light italic">
                          No se registraron correos.
                        </p>
                      )}
                    </dd>
                  </div>

                  <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Sede de la herramienta
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {loan.cellarTool?.name}
                    </dd>
                  </div>

                  <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Sede destino
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {loan.cellar?.name}
                    </dd>
                  </div>

                  <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Prestamista
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {loan.userNameCreatedBy}
                    </dd>
                  </div>

                  <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Prestatario
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {loan.userName}
                    </dd>
                  </div>

                  <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Fecha de préstamo
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {loan.creationDate}
                    </dd>
                  </div>

                  <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Fecha de devolución
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {loan.finishDate}
                    </dd>
                  </div>

                  <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">Estado</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <StatusField
                        statusId={loan.status}
                        children={<p>{loan.status}</p>}
                      />
                    </dd>
                  </div>
                </div>
                <div className="w-2/6 h-60 overflow-y-scroll">
                  {loan.logs ? (
                    loan.logs &&
                    Object.entries(JSON.parse(loan.logs))
                      .sort((a, b) => new Date(a[1].date) - new Date(b[1].date))
                      .map(([key, item]) => (
                        <div className="p-4 bg-white text-sm">
                          <StatusField statusId={key} children={<p>{key}</p>} />
                          <div className="flex">
                            <p className="font-semibold mr-2">Creado por:</p>
                            {item.createdBy}
                          </div>
                          <div className="flex">
                            <p className="font-semibold mr-2">Fecha:</p>
                            <Moment locale="co" format="YYYY-MM-DD HH:mm">
                              {new Date(item.date)}
                            </Moment>
                          </div>
                        </div>
                      ))
                  ) : (
                    <p className="font-light italic">
                      No hay logs disponibles.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
};
export default LoanDetail;
