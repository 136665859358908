import Moment from "react-moment";
import StatusField from "../../../../ui/statusUi/StatusField";
import TableTitleGoTo from "../../../../ui/table/titles/TableTitleGoTo";

export const requestTableColumns = [
  {
    Header: " ",
    columns: [
      /* {
        Header: " ",
      }, */
      {
        Header: "# solicitud",
        accessor: "id",
      },
      {
        Header: "Fecha de apertura",
        accessor: "creationDate",
        Cell: (props) => {
          const cell = props.row.original;
          return (
            <Moment locale="co" format="YYYY-MM-DD HH:mm">
              {new Date(cell.creationDate)}
            </Moment>
          );
        },
      },
      {
        Header: "Asunto",
        accessor: "name",
      },
      {
        Header: "Servicios",
        accessor: (row) =>
          row.services.map((service) => service.description).join(", "), //sólo busca por el nombre de los servicios
      },
      {
        Header: "Estado",
        accessor: (row) =>
          row.services.map((service) => service.statusId).join(", "), //sólo busca por el statusId de los servicios, no por estado
        Cell: (props) => {
          const services = props.row.original.services;
          return services.map((service) => (
            <StatusField
              key={service.id}
              service={service}
              statusId={service.statusId}
              children={<p>{service.statusId}</p>}
            />
          ));
        },
      },

      {
        Header: "Acción",
        accessor: "moreInfo",
        Cell: TableTitleGoTo,
        disableSortBy: true,
        disableFilters: true,
      },
    ],
  },
];
