import React from "react";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { LISTCELLARS } from "../../../../../graphql/queries/cellar/listCellars";

const SelectCellar = ({ cellarId, onChangeCellar, onChangeCellarItem }) => {
  const { company, ...stateProps } = useSelector((state) => state.auth);

  const { data, loading, error } = useQuery(LISTCELLARS, {
    variables: { companyId: company.id },
  });

  if (loading) return <p>Cargando...</p>;
  /* if (error) return console.log(error); */

  return (
    <>
      {data && data.listCellars && (
        <select
          className="select w-4/5 "
          onChange={(e) => {
            if (data.listCellars && onChangeCellarItem) {
              const item = data.listCellars.find(
                (_item) => _item.id === e.target.value
              );
              if (item) onChangeCellarItem(item);
            } else if (data.listCellars && onChangeCellar) {
              onChangeCellar(e.target.value);
            }
          }}
          value={cellarId}
        >
          <option disabled selected>
            Selecciona una sede
          </option>

          {data.listCellars.map((item) => {
            if (stateProps.cellarId != null) {
              if (item.id === stateProps.cellarId) {
                return (
                  <option
                    className="display-inline"
                    key={item.id}
                    value={item.id}
                  >
                    {item.name}
                  </option>
                );
              }
            } else {
              return (
                <option
                  className="display-inline"
                  key={item.id}
                  value={item.id}
                >
                  {item.name}
                </option>
              );
            }
          })}
        </select>
      )}
    </>
  );
};

export default SelectCellar;
