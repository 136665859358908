import * as React from "react";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { LOGIN } from "../../../graphql/mutations/auth/login";
import { useForm } from "../../../hooks/useForm";
import {
  startCheckingCredentials,
  startSignIn,
} from "../../../store/auth/thunks";
import { PrivateRoutes, PublicRoutes } from "../../../router/routes";
import { ScreenForm } from "../layout/ScreenForm";
import { AlertMessageWindow } from "../../ui/alerts/AlertMessageWindow";
import {
  adminActions,
  guestActions,
  operatorActions,
  providerActions,
} from "../../../guards/actions.guard";
import Loading from "../../ui/loading/Loading";

const formData = {
  email: "",
  password: "",
};

const formValidations = {
  email: [(value) => value.length >= 1, "El correo es obligatorio."],
  password: [(value) => value.length >= 1, "La contraseña es obligatoria."],
};
export var dataResponse = [];

export default function FormLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loginMutationFunction, { error, loading, reset }] = useMutation(LOGIN);
  /* console.log("error", error) */
  const [formSubmitted, setFormSubmitted] = useState(false);

  const { email, password, onInputChange, emailValid, passwordValid } = useForm(
    formData,
    formValidations
  );

  const [isShowingPassword, setIsShowingPassword] = useState(false);

  const OnLogin = async (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    dispatch(startCheckingCredentials());

    if (!emailValid && !passwordValid) {
      await loginMutationFunction({
        variables: {
          userId: email,
          password: password,
        },
        onCompleted: (data) => {
          dataResponse = data.login;
        },
      })
        .then(() => {
          var actions = [""];

          const { roleId } = dataResponse;

          if (roleId === "admin") {
            actions = adminActions;
          } else if (roleId === "guest") {
            actions = guestActions;
          } else if (roleId === "operator") {
            actions = operatorActions;
          } else if (roleId === "provider") {
            actions = providerActions;
          }

          dispatch(
            startSignIn({
              authMutationStatus: true,
              actions: actions,
              ...dataResponse,
            })
          );

          const roleRoutes = {
            admin: `${PrivateRoutes.HOME}/${PrivateRoutes.ADMIN}/${PrivateRoutes.USER}`,
            operator: `${PrivateRoutes.HOME}/${PrivateRoutes.ADMIN}/${PrivateRoutes.USER}`,
            guest: `${PrivateRoutes.HOME}/${PrivateRoutes.PRODUCT}`,
            provider: `${PrivateRoutes.HOME}/${PrivateRoutes.REQUEST}`,
          };

          navigate(roleRoutes[roleId], {
            replace: true,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch(
            startSignIn({
              authMutationStatus: false,
              errorMessage: err.message,
            })
          );
        });
    }
  };

  const inputForm =
    !!emailValid && formSubmitted ? "border border-red-700" : "";

  if (loading) return <Loading />;

  return (
    <ScreenForm>
      <div className="mx-auto max-w-sm">
        <div className="mt-8">
          <div>
            <label className="text-md font-medium text-base text-gray-800">
              Correo
            </label>

            <input
              type="email"
              className={`${inputForm} my-2 w-full rounded-xl p-4 mt-1 border border-primary peer`}
              value={email}
              placeholder="Escribe tu email"
              name="email"
              onChange={onInputChange}
              required
            />
            {/* False */}
            {!!emailValid && formSubmitted && (
              <p className="text-red-500 text-sm">{emailValid}</p>
            )}
          </div>
        </div>
        <div className="mt-8">
          <div>
            <label className="text-md font-medium text-base text-gray-800">
              Contraseña
            </label>

            <div className="relative">
              <input
                type={isShowingPassword ? "text" : "password"}
                className={`${inputForm} my-2 w-full rounded-xl p-4 mt-1 border border-primary peer`}
                value={password}
                placeholder="Escribe tu contraseña"
                name="password"
                onChange={onInputChange}
                required
              />

              {!!passwordValid && formSubmitted && (
                <p className="text-red-500 text-sm mt-1">{passwordValid}</p>
              )}

              <button
                className="absolute right-3 top-1/2 transform -translate-y-1/2"
                onClick={() => setIsShowingPassword(!isShowingPassword)}
              >
                {isShowingPassword ? (
                  <span className="material-symbols-outlined opacity-50">
                    visibility_off
                  </span>
                ) : (
                  <span className="material-symbols-outlined opacity-50">
                    visibility
                  </span>
                )}
              </button>
            </div>
          </div>

          <div className="mt-8 flex justify-between items-center">
            <NavLink
              className="font-medium text-base text-amber-700"
              to={PublicRoutes.RECOVERY_PASSWORD}
            >
              Olvidé mi contraseña
            </NavLink>
          </div>
          <button
            onClick={OnLogin}
            className={`cursor-pointer w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 mt-4 rounded-xl flex justify-center bg-primary text-white text-md font-bold`}
          >
            <div>Entrar</div>
          </button>
          {error && (
            <AlertMessageWindow
              text={"Usuario o contraseña incorrectos."}
              bgcolor={"red"}
              valuebgcolor={300}
              textcolor={"red"}
              valuebgtext={700}
              message={error.message}
              onDismiss={() => reset()}
            />
          )}
        </div>
      </div>
    </ScreenForm>
  );
}
