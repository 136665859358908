import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import IconTextButton from "../../../../ui/buttons/IconTextButton";
import ModalTemplate from "../../../../ui/modals/ModalTemplate";
import { startInModal } from "../../../../../store/tab/thunks";
import RequestForm from "../layout/RequestForm";
import { CREATEORDERS } from "../../../../../graphql/mutations/order/createOrder";
import { LISTORDERS } from "../../../../../graphql/queries/order/listOrders";
import { toast } from "react-toastify";
import checkRequestFields from "../helpers/checkRequestFields";
import REQUEST_VALIDATIONS from "../helpers/validations";
import { useEffect } from "react";
import successToast from "../../../../../utils/toast/successToast";
import errorToast from "../../../../../utils/toast/errorToast";
import uploadFile from "../../../../../utils/uploadFile";

function AddRequest() {
  const dispatch = useDispatch();
  const { company, cellarId, roleId, id } = useSelector((state) => state.auth);

  const [createOrderMutationFunction, { loading }] = useMutation(CREATEORDERS, {
    refetchQueries: [{ query: LISTORDERS }, "ListOrders"],
  });
  const serviceTypes = ["Servicio de garantía", "Revisión o mantenimiento"];

  const [services, setServices] = useState([
    {
      description: "",
      serviceTypeId: serviceTypes[0],
      cellarId: null,
      productId: null,
      creationDate: "",
      imgsURL: [],
      imgBlob: [],
      imgType: [],
      statusId: "ABIERTO",
      emails: [],
      date: "",
      cellarName: "",
      productName: "",
    },
  ]);

  const [order, setOrder] = useState({
    name: "",
    description: "",
    companyId: company.id,
    cellarId: roleId === "guest" || roleId === "operator" ? cellarId : null,
    services: services,
  });

  const [validations, setValidations] = useState(REQUEST_VALIDATIONS);

  useEffect(() => {
    const arrServicesProps = [];
    services.map((i) => {
      arrServicesProps.push({
        cellarId: i.cellarId,
        description: i.description,
        serviceTypeId: i.serviceTypeId,
        creationDate: "",
        imgsURL: i.imgsURL,
        productId: i.productId,
        statusId: "ABIERTO",
        emails: [],
        date: "",
        cellarName: i.cellarName,
        productName: i.productName,
      });
    });
    setOrder({
      name: order.name,
      description: "",
      companyId: company.id,
      cellarId: roleId === "guest" || roleId === "operator" ? cellarId : null,
      services: arrServicesProps,
    });
  }, [services]);

  console.log(services);

  const onClickAdd = async () => {
    const { valid, toastText, toastInfo, newValidations } = checkRequestFields(
      order,
      validations
    );

    var validSelect = true;
    if (order.name.length === 0) {
      validSelect = false;
    } else {
      services.map((item) => {
        if (
          item.description.length === 0 ||
          item.cellarId == null ||
          item.cellarName.length === 0 ||
          item.productName.length === 0 ||
          item.productId == null ||
          item.emails.length === 0 ||
          item.date.length === 0
        ) {
          console.log("validSelect = false");
          validSelect = false;
        }
      });
    }

    if (!valid) {
      toast.error(toastText, toastInfo);
      setValidations({ ...newValidations });
    } else {
      if (!validSelect) {
        const resp = {
          toastText: "ERROR: Verifica los campos requeridos del servicio.",
          toastInfo: {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        };
        toast.error(resp.toastText, resp.toastInfo);
      } else {
        //Adición de imagen

        const allImgsURL = [];
        services.map(async (service, serviceIndex) => {
          if (service.imgBlob != null) {
            service.imgsURL.map((imgURL, imgURLIndex) => {
              return allImgsURL.push({
                imgURLIndex,
                serviceIndex,
                blob: service.imgBlob[imgURLIndex],
                type: service.imgType[imgURLIndex],
                name: imgURL.name,
              });
            });
          }
        });

        /* console.log("allImgsURL", allImgsURL); */
        const itemsToAdd = await Promise.all(
          allImgsURL.map(async (img) => {
            return {
              img,
              result: await uploadFile(
                `products/${img.name}`,
                img.blob,
                img.type
              ),
            };
          })
        );
        /* console.log("itemsToADD", itemsToAdd); */

        services.map((service, serviceIndex) => {
          service.imgsURL = [];

          const newImgsURL = [];
          itemsToAdd.map((itemResult) => {
            if (itemResult && itemResult.result) {
              if (itemResult.img.serviceIndex === serviceIndex) {
                var res = {
                  name: itemResult.img.name,
                  url: itemResult.result.Location,
                };
                newImgsURL[itemResult.img.imgURLIndex] = res;
              }
            }
          });
          service.imgsURL = newImgsURL;
        });

        /* console.log("services!!!!!", services); */
        setServices([...services]);
        console.log("EMAILS!!!! ADD REQUEST", services);
        const arrServicesProps = [];
        /* console.log("servicesEffect"); */
        services.map((i) => {
          return arrServicesProps.push({
            cellarId: i.cellarId,
            cellarName: i.cellarName,
            description: i.description,
            serviceTypeId: i.serviceTypeId,
            imgsURL: i.imgsURL,
            productId: i.productId,
            productName: i.productName,
            statusId: "ABIERTO",
            emails: i.emails,
            date: i.date,
          });
        });
        addOrder(arrServicesProps);
      }
    }
  };

  const addOrder = async (servicesData) => {
    // const _services = updateServices(servicesData);

    const newOrder = { ...order, services: servicesData };
    console.log("newOrder", {
      variables: {
        input: newOrder,
        createdBy: id,
      },
    });

    await createOrderMutationFunction({
      variables: {
        input: newOrder,
        createdBy: id,
      },
    })
      .then((data) => {
        toast.success("Solicitud realizada con éxito.", successToast);
        dispatch(startInModal({ inModal: null }));
      })
      .catch((error) => {
        toast.error(error.toString(), errorToast);
      });
  };

  return (
    <>
      <ModalTemplate
        title={"Añadir solicitud"}
        onClickBack={() => {
          dispatch(startInModal({ inModal: null }));
        }}
      >
        <>
          <RequestForm
            order={order}
            setOrder={setOrder}
            name={"solicitud"}
            services={services}
            setServices={setServices}
            serviceTypes={serviceTypes}
            validations={validations}
            setValidations={setValidations}
          />

          <div className="my-4 bg-white place-content-end flex p-4 rounded-lg shadow-sm">
            <IconTextButton
              icon="done"
              text="Guardar"
              onClickButton={onClickAdd}
              loading={loading}
            />
          </div>
        </>
      </ModalTemplate>
    </>
  );
}

export default AddRequest;
