import isValidDate from "../../../../../utils/checkDateRange";
import checkLength from "../../../../../utils/checkLength";

export default function checkRequestFields(order, validations) {
  const resp = {
    valid: true,
    toastText: "ERROR: Verifica los campos requeridos de la solicitud.",
    toastInfo: {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    },
  };

  validations.name = checkLength(order.name);
  validations.creationDate = isValidDate(order.creationDate);

  if (
    !validations.name
  ) {
    resp.valid = false;
  }

  resp.newValidations = validations;

  return resp;
}
