import React from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { PublicRoutes } from "../../../router/routes";
import { CONFIRMFORGOTPASSWORD } from "../../../graphql/mutations/auth/confirmForgotPassword";
import errorToast from "../../../utils/toast/errorToast";
import successToast from "../../../utils/toast/successToast";
import { toast } from "react-toastify";
import { ScreenForm } from "../layout/ScreenForm";
import Icon from "../../ui/buttons/Icon";
import Loading from "../../ui/loading/Loading";
import TextInput from "../../ui/Inputs/TextInput";
import checkUserRecoveryFields from "../../admin/pages/users/helpers/checkUserRecovery";
import USER_VERIFICATION_VALIDATIONS from "../../admin/pages/users/helpers/userRecoveryValidations";
import {
  LENGTH_ERROR,
  PASSWORD_ERROR,
} from "../../admin/pages/users/helpers/inputErrors";
import checkPass from "../../../utils/checkPass";
import checkLengthCode from "../../../utils/checkLengthCode";

const InsertCode = () => {
  const params = useLocation();
  const { email } = params.state.email.email;
  const navigate = useNavigate();

  const [confirmforgotPasswordMutationFunction, { loading }] = useMutation(
    CONFIRMFORGOTPASSWORD
  );
  const [validations, setValidations] = useState(USER_VERIFICATION_VALIDATIONS);
  const [userRecovery, setUserRecovery] = useState({
    password: "",
    verificationCode: "",
  });

  const onClickConfirmRecovery = async (event) => {
    event.preventDefault();
    const { valid, toastText, toastInfo, newValidations } =
      checkUserRecoveryFields(userRecovery, validations);

    if (!valid) {
      toast.error(toastText, toastInfo);
      setValidations({ ...newValidations });
    } else {
      await confirmforgotPasswordMutationFunction({
        variables: {
          userId: email,
          verificationCode: userRecovery.verificationCode,
          newPassword: userRecovery.password,
        },
      })
        .then((data) => {
          toast.success(
            "Se ha actualizado la contraseña con éxito.",
            successToast
          );
          /* console.log(data); */
          navigate(PublicRoutes.LOGIN, {
            replace: true,
          });
        })
        .catch((error) => {
          /* console.log(error); */
          toast.error(error.toString(), errorToast);
        });
    }
  };

  if (loading) return <Loading />;

  return (
    <ScreenForm>
      <div className="mt-8">
        <div>
          <TextInput
            title={"Código de verificación"}
            value={userRecovery.verificationCode}
            onChangeValue={(e) => {
              setUserRecovery({
                ...userRecovery,
                verificationCode: e.target.value,
              });
              setValidations({
                ...validations,
                verificationCode: checkLengthCode(e.target.value),
              });
            }}
            error={LENGTH_ERROR}
            isValidated={validations.verificationCode}
          />

          <TextInput
            title={"Contraseña"}
            type={"password"}
            value={userRecovery.password}
            onChangeValue={(e) => {
              setUserRecovery({ ...userRecovery, password: e.target.value });
              setValidations({
                ...validations,
                password: checkPass(e.target.value),
              });
            }}
            error={PASSWORD_ERROR}
            isValidated={validations.password}
          />
        </div>
        <button
          onClick={onClickConfirmRecovery}
          className={`cursor-pointer w-full mb-2 active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 mt-4 rounded-xl flex justify-center bg-primary text-white text-md font-bold`}
        >
          <div>Confirmar código</div>
        </button>

        <Link
          className="flex items-center font-bold text-amber-700"
          to={PublicRoutes.LOGIN}
        >
          <Icon icon="arrow_back" /> Regresar al inicio de sesión
        </Link>
      </div>
    </ScreenForm>
  );
};

export default InsertCode;
