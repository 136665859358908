import { Navigate, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Admin from "../components/admin/Admin";
import FormLogin from "../components/auth/pages/FormLogin";
import { RecoveryPassword } from "../components/auth/pages/ForgotPassword";
import AuthGuard from "../guards/auth.guard";
import RoutesWithNotFound from "../utils/routesWithNotFound";
import { PrivateRoutes, PublicRoutes } from "./routes";
import { UsersProvider } from "../components/admin/pages/users/context/users.context";
import { RoleGuard, RoleGuardZone } from "../guards/role.guard";
import InsertCode from "../components/auth/pages/InsertCode";
import { Home } from "../components/home/Home";
import Users from "../components/admin/pages/users/Users";
import Zone from "../components/home/components/zone/layout/Zone";
import Request from "../components/home/components/request/layout/Request";
import { Loan } from "../components/home/components/loan/layout/Loan";
import Upload from "../components/home/components/upload/layout/Upload";
import Tool from "../components/home/components/tools/layout/Tool";
import Product from "../components/home/components/product/layout/Product";
import ProductApproved from "../components/home/components/productApproved/layout/ProductApproved";
import UploadTools from "../components/home/components/uploadTools/layout/UploadTools";

export const AppRouter = () => {
  const { roleId, disabled } = useSelector((state) => state.auth);
  console.log("Deshabilitado??", disabled);

  return (
    <>
      <RoutesWithNotFound>
        <Route path="/" element={<Navigate to={PublicRoutes.LOGIN} />} />

        <Route path={PublicRoutes.LOGIN} element={<FormLogin />} />
        <Route
          path={PublicRoutes.RECOVERY_PASSWORD}
          element={<RecoveryPassword />}
        />
        <Route path={PublicRoutes.INSERT_CODE} element={<InsertCode />} />

        <Route element={<AuthGuard privateValidation={true} />}>
          <Route path={`${PrivateRoutes.HOME}/*`} element={<Home />}>
            <Route
              path="*"
              element={<Navigate replace to={PrivateRoutes.REQUEST} />}
            />

            <Route path={PrivateRoutes.PRODUCT} element={<Product />} />

            <Route element={<RoleGuardZone role={roleId} />}>
              <Route
                path={PrivateRoutes.PRODUCT_APPROVED}
                element={<ProductApproved />}
              />
            </Route>

            <Route path={PrivateRoutes.REQUEST} element={<Request />} />

            <Route element={<RoleGuardZone role={roleId} />}>
              <Route path={PrivateRoutes.ZONE} element={<Zone />} />
            </Route>

            <Route path={PrivateRoutes.LOAN} element={<Loan />} />

            <Route element={<RoleGuardZone role={roleId} />}>
              <Route path={PrivateRoutes.UPLOAD} element={<Upload />} />
            </Route>

            <Route element={<RoleGuardZone role={roleId} />}>
              <Route path={PrivateRoutes.UPLOADTOOLS} element={<UploadTools />} />
            </Route>

            <Route path={PrivateRoutes.TOOL} element={<Tool />} />

            <Route element={<RoleGuard role={roleId} />}>
              <Route path={PrivateRoutes.ADMIN} element={<Admin />}>
                <Route
                  index
                  element={
                    <UsersProvider>
                      {" "}
                      <Users />{" "}
                    </UsersProvider>
                  }
                />
                <Route
                  path={PrivateRoutes.USER}
                  element={
                    <UsersProvider>
                      <Users />
                    </UsersProvider>
                  }
                />
              </Route>
            </Route>
          </Route>
        </Route>
      </RoutesWithNotFound>
    </>
  );
};
