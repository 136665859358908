import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Navbar } from "../ui/navbar/Navbar";
import { Sidebar } from "../ui/sidebar/Sidebar";

export const Home = () => {
  const [sidebarOpen, setSideBarOpen] = useState(true);
  
  const handleViewSidebar = () => {
    setSideBarOpen(!sidebarOpen);
  };

  return (
    <div className="">
      <Navbar toggleSidebar={handleViewSidebar} />
      <div className="md:flex">
        <Sidebar isOpen={sidebarOpen} />

        <div className="flex-1 overflow-y-scroll max-h-[100vh] md:relative md:max-h-[91vh]">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
