import { checkingCredentials, login, logout } from "./authSlice";
import { toast } from "react-toastify";
import errorToast from "../../utils/toast/errorToast";

export const startCheckingCredentials = () => {
  return async (dispatch) => {
    dispatch(checkingCredentials());
  };
};

export const startSignIn = ({
  authMutationStatus,
  actions,
  id,
  name,
  roleId,
  imgURL,
  company,
  cellarId,
  disabled,
  role,
}) => {
  return async (dispatch) => {
    const resp = {
      authMutationStatus,
      actions,
      id,
      name,
      roleId,
      imgURL,
      company,
      cellarId,
      disabled,
      role,
    };
    /* console.log(resp) */
    if (!resp.authMutationStatus) return dispatch(logout(resp.errorMessage));
    if (disabled !== true) {
      return dispatch(login(resp));
    } else {
      toast.error(
        "El usuario se encuentra deshabilitado. Comúniquese con el administrador.",
        errorToast
      );
    }
  };
};
