import { useQuery } from "@apollo/client";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { LISTUSERS } from "../../../../graphql/queries/user/listUsers";
import AddUser from "./components/AddUser";
import EditUser from "./components/EditUser";
/* import makeData from "./makeData"; */
import { userTableColumns } from "./usersTableColumns";
import { useDispatch, useSelector } from "react-redux";
import TabLayout from "../../../home/components/shared/TabLayout";
import { startComponent, startDataCRUD } from "../../../../store/tab/thunks";
import ViewUser from "./components/ViewUser";
import Loading from "../../../ui/loading/Loading";

/* const serverData = makeData(10000);
 */
const Users = () => {
  const dispatch = useDispatch();
  const { company, id } = useSelector((state) => state.auth);
  const { dataResponse } = useSelector((state) => state.tab);

  const { data, loading } = useQuery(LISTUSERS, {
    variables: { companyId: company.id },
    onCompleted: (data) => {
      /* const filteredData = data.listUsers.filter(
        (user) => user.id !== "felipe.castillo@azlogica.com"
      );
      dispatch(
        startDataCRUD({
          dataResponse: filteredData,
        })
      ); */
      dispatch(
        startDataCRUD({
          dataResponse: data.listUsers,
        })
      );
    },
  });

  console.log("users", data);

/*   console.log("data", data)
  console.log("dataResponse", dataResponse) */

  const columns = useMemo(() => userTableColumns, []);

  // We'll start our table without any data
  //  const [data, setData] = useState([])
  // const [loading, setLoading] = useState(false)
/*   const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0); */

  // const fetchData = useCallback(
  //   ({ pageSize, pageIndex, sortBy, filters, groupBy }) => {
  //     const fetchId = ++fetchIdRef.current;
  //     //   setLoading(true)

  //     // We'll even set a delay to simulate a server here
  //     setTimeout(() => {
  //       // Only update the data if this is the latest fetch
  //       if (fetchId === fetchIdRef.current) {
  //         /* console.log("pageIndex", pageIndex); */
  //         const startRow = pageSize * pageIndex;
  //         const endRow = startRow + pageSize;
  //         //   setData(serverData.slice(startRow, endRow))

  //         // Your server could send back total page count.
  //         // For now we'll just fake it, too
  //         setPageCount(Math.ceil(serverData.length / pageSize));

  //         // setLoading(false)
  //       }
  //     }, 1000);
  //   },
  //   []
  // );

  dispatch(startComponent({ component: "User" }));
  if (loading) return <Loading />;

  return (
    <div>
      <TabLayout
        titleComponent={"Usuarios"}
        AddElement={AddUser}
        EditElement={EditUser}
        ViewElement={ViewUser}
        nameElement={"usuario"}
        data={data}
        dataQuery={dataResponse}
        columns={columns}
        // fetchData={fetchData}
        loading={loading}
        // pageCount={pageCount}
      />
    </div>
  );
};

export default Users;
