import * as AWS from "aws-sdk";

export default async function uploadFile(url, blob, contentType) {
  return new Promise((resolve, reject) => {
    const bucket = new AWS.S3();

    bucket.upload(
      {
        Bucket: "az-icon",
        Key: url,
        Body: blob,
        ContentType: contentType,
        // ACL: 'public-read'
      },
      function (err, data) {
        if (err) {
          console.log("There was an error uploading your file: ", err.message);
          /* console.log(err); */
          reject(err);
        }
        resolve(data);

        /* console.log(data); */
      }
    );
  });
}
