import React from "react";
import checkLength from "../../../../../utils/checkLength";
import { LENGTH_ERROR } from "../../../../admin/pages/users/helpers/inputErrors";
import IconTextButton from "../../../../ui/buttons/IconTextButton";
import TextInput from "../../../../ui/Inputs/TextInput";
import SelectCellar from "../../shared/Inputs/SelectCellar";
import SelectProduct from "../../shared/Inputs/SelectProduct";
import { toast } from "react-toastify";
import { resizeFile } from "../../../../../utils/resizeFile";
import InputFiles from "../../../../ui/buttons/InputFiles";
import SelectStatus from "../../shared/Inputs/SelectStatus";
import { ActionHiddenInputGuard } from "../../../../../guards/ActionHiddenInputGuard.guard";
import { useSelector } from "react-redux";
import ImageModal from "../../../../ui/modals/ImageModal";
import StatusField from "../../../../ui/statusUi/StatusField";
import Moment from "react-moment";
import { useState } from "react";
import checkEmail from "../../../../../utils/checkEmail";

const fileTypes = ["JPEG", "PNG", "GIF"];

const RequestForm = ({
  order,
  setOrder,
  services,
  setServices,
  serviceTypes,
  validations,
  setValidations,
  onChangeService,
}) => {
  const { inModal } = useSelector((state) => state.tab);
  /* 
  console.log("services",services); */

  const onClickAddService = () => {
    var validSelect = true;

    if (order.name.length === 0) {
      validSelect = false;
    } else {
      services.map((item) => {
        if (
          item.description.length === 0 ||
          item.cellarId == null ||
          item.productId == null ||
          item.emails.length === 0
        ) {
          validSelect = false;
        }
      });
    }

    if (!validSelect) {
      const resp = {
        toastText:
          "ERROR: Para añadir otro servicio verifica los campos requeridos.",
        toastInfo: {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      };
      toast.error(resp.toastText, resp.toastInfo);
    } else {
      setServices([
        ...services,
        {
          description: "",
          serviceTypeId: serviceTypes[0],
          cellarId: null,
          productId: null,
          statusId: "ABIERTO",
          imgsURL: [],
          imgBlob: [],
          imgType: [],
          emails: [],
        },
      ]);
    }
  };

  const onDeleteService = (index) => {
    setServices(services.filter((s, i) => i !== index));
  };

  const updateServiceDescription = (index, value) => {
    setServices(
      services.map((s, i) => (i === index ? { ...s, description: value } : s))
    );
  };

  const onChangeValueType = (index, type) => {
    setServices(
      services.map((s, i) => (i === index ? { ...s, serviceTypeId: type } : s))
    );
  };

  const onChangeValueCellar = (index, cellarId) => {
    setServices(
      services.map((s, i) => (i === index ? { ...s, cellarId: cellarId } : s))
    );
  };

  const onChangeValueCellarItem = (index, item) => {
    setServices(
      services.map((s, i) =>
        i === index ? { ...s, cellarId: item.id, cellarName: item.name } : s
      )
    );
  };

  const onChangeValueProduct = (index, productId) => {
    setServices(
      services.map((s, i) => (i === index ? { ...s, productId: productId } : s))
    );
  };

  const onChangeValueProductItem = (index, item) => {
    setServices(
      services.map((s, i) =>
        i === index ? { ...s, productId: item.id, productName: item.name } : s
      )
    );
  };

  const onChangeStatus = (index, statusId) => {
    setServices(
      services.map((s, i) => (i === index ? { ...s, statusId: statusId } : s))
    );

    onChangeService({ ...services[index], statusId: statusId });
  };

  const setImagesURL = async (filelist, index) => {
    console.log("filelist", filelist);
    let arrImages = [];
    let arrTypes = [];
    let arrImagesBlob = [];

    //Si dentro del filelist hay más de 1 elemento
    if (filelist.length > 0) {
      //Itera todo el filelist[]
      for (var i = 0, file; (file = filelist[i]); i++) {
        //Si existe el archivo y tiene un size
        if (file && file.size) {
          //Extrae el blob y url local
          const newFile = await resizeFile(file, {
            maxWidth: 512,
            maxHeight: 512,
          });
          var _file = URL.createObjectURL(newFile);

          //Push del objeto con su name y url hacia el array de todos las imagenes
          arrImages.push({ name: file.name, url: _file });
          //Push del tipo hacia el array de todos los tipos
          arrTypes.push(file.type);
          //Push del objeto con su blob hacia el array de todos los blob
          arrImagesBlob.push(newFile);
        }
      }

      setServices(
        services.map((s, i) =>
          i === index
            ? {
                ...s,
                imgsURL: arrImages,
                imgBlob: arrImagesBlob,
                imgType: arrTypes,
              }
            : s
        )
      );
    }
  };

  const onClickDeleteImages = (index, indexService) => {
    if (indexService !== null) {
      setServices(
        services.map((s, i) =>
          i === indexService
            ? {
                ...s,
                imgsURL: s.imgsURL && s.imgsURL.filter((s, i) => i !== index),
                imgBlob: s.imgBlob && s.imgBlob.filter((s, i) => i !== index),
                imgType: s.imgType && s.imgType.filter((s, i) => i !== index),
              }
            : s
        )
      );
    }
  };

  //Add email inputs
  const onChangeEmail = (value, indexService, iEmail) => {
    /* console.log("onChange", value); */

    setServices(
      services.map((s, i) =>
        i === indexService
          ? {
              ...s,
              emails:
                s.emails && s.emails.map((s, i) => (i === iEmail ? value : s)),
            }
          : s
      )
    );
  };

  const onClickAddEmailInput = (indexService) => {
    /* console.log("add email");
    console.log("service", indexService); */

    setServices(
      services.map((s, i) =>
        i === indexService ? { ...s, emails: [...s.emails, ""] } : s
      )
    );
  };

  const onDeleteEmail = (index, indexService) => {
    /* console.log("delete", index, indexService); */
    setServices(
      services.map((s, i) =>
        i === indexService
          ? { ...s, emails: s.emails && s.emails.filter((s, i) => i !== index) }
          : s
      )
    );
  };

  const onChangeDate = (index, value) => {
    setServices(
      services.map((s, i) => (i === index ? { ...s, date: value } : s))
    );
  };

  return (
    <>
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6 bg-slate-200">
          <div className="md:flex">
            <div className="w-full h-fit">
              <dt className="text-sm font-medium text-gray-500">Asunto</dt>
              <TextInput
                value={order.name}
                onChangeValue={(e) => {
                  setOrder({ ...order, name: e.target.value });
                  setValidations({
                    ...validations,
                    name: checkLength(e.target.value),
                  });
                }}
                error={LENGTH_ERROR}
                isValidated={validations.name}
              />
            </div>
          </div>
        </div>

        <div className="mt-3">
          <dl>
            {services.map((service, indexService) => (
              <div
                className={`collapse ${
                  indexService === 0 ? "collapse-open" : ""
                } collapse-arrow`}
              >
                <input type="checkbox" className="peer" />
                <div className="collapse-title bg-slate-200 peer-checked:text-secondary-content">
                  <div className="">
                    <h3 className="text-md font-medium leading-6 text-slate-700">
                      <div>Detalles del servicio </div>
                    </h3>
                  </div>
                </div>

                <div className="collapse-content text-primary-content peer-checked:text-secondary-content">
                  <div className="flex w-full">
                    <div className="w-4/6">
                      {indexService !== 0 && (
                        <ActionHiddenInputGuard
                          typeModal={inModal}
                          childrenEdit={""}
                          childrenAdd={
                            <button
                              onClick={() => onDeleteService(indexService)}
                              className="btn btn-sm bg-red-500 hover:bg-red-700 text-white font-medium py-1 px-2 border-none md:absolute right-2 md:right-[2rem] md:top-[5.5rem]"
                            >
                              Eliminar
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="hidden md:h-6 md:w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </button>
                          }
                        />
                      )}

                      <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Descripción
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          <ActionHiddenInputGuard
                            typeModal={inModal}
                            childrenEdit={<p>{service.description}</p>}
                            childrenAdd={
                              <TextInput
                                value={service.description}
                                onChangeValue={(e) => {
                                  updateServiceDescription(
                                    indexService,
                                    e.target.value
                                  );
                                  setValidations({
                                    ...validations,
                                    description: checkLength(e.target.value),
                                  });
                                }}
                                error={LENGTH_ERROR}
                                isValidated={validations.description}
                              />
                            }
                          />
                        </dd>
                      </div>

                      <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Tipo
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          <ActionHiddenInputGuard
                            typeModal={inModal}
                            childrenEdit={
                              <>
                                <p>{service.serviceTypeId}</p>
                              </>
                            }
                            childrenAdd={
                              <>
                                {serviceTypes.map((type) => (
                                  <>
                                    <div
                                      onChange={(e) => {
                                        onChangeValueType(indexService, type);
                                        setValidations({
                                          ...validations,
                                          description: checkLength(
                                            e.target.value
                                          ),
                                        });
                                      }}
                                    >
                                      <input
                                        checked={service.serviceTypeId === type}
                                        type="radio"
                                        key={`${type}${indexService}`}
                                        id={`${type}${indexService}`}
                                        name={`${indexService}`}
                                        error={LENGTH_ERROR}
                                        isValidated={validations.description}
                                      />
                                      <label htmlFor={`${type}${indexService}`}>
                                        {type}
                                      </label>
                                    </div>
                                  </>
                                ))}
                              </>
                            }
                          />
                        </dd>
                      </div>

                      <div>
                        <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Imágenes
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <ActionHiddenInputGuard
                              typeModal={inModal}
                              childrenEdit={
                                <div className="">
                                  {service.imgsURL.length > 0 ? (
                                    service.imgsURL.map((i, index) => (
                                      <ImageModal
                                        url={i.url}
                                        name={i.name}
                                        indexService={indexService}
                                        index={index}
                                      />
                                    ))
                                  ) : (
                                    <p className="font-light italic">
                                      No hay imágenes para mostrar
                                    </p>
                                  )}
                                </div>
                              }
                              childrenAdd={
                                <div className="">
                                  {service.imgsURL.length > 0 ? (
                                    service.imgsURL.map((i, index) => (
                                      <div className="flex w-[25%] justify-start items-center">
                                        <ImageModal
                                          url={i.url}
                                          name={i.name}
                                          indexService={indexService}
                                          index={index}
                                        />
                                        <button
                                          id={`img${index}`}
                                          onClick={() =>
                                            onClickDeleteImages(
                                              index,
                                              indexService
                                            )
                                          }
                                          className="btn btn-sm btn-circle bg-red-500 hover:bg-red-700 text-white font-medium py-1 px-2 border-none"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="md:h-6 md:w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M6 18L18 6M6 6l12 12"
                                            />
                                          </svg>
                                        </button>
                                      </div>
                                    ))
                                  ) : (
                                    <p>
                                      Selecciona uno o varios archivos:
                                      <p className="text-red-600 font-semibold">
                                        *Formatos permitidos: jpeg, jpg, png
                                      </p>
                                      <InputFiles
                                        id={`imgRequest${indexService}`}
                                        onChangeFile={setImagesURL}
                                        index={indexService}
                                      >
                                        <img
                                          src={"/images/addImage.jpg"}
                                          className="cursor-pointer m-2 w-20 justify-center"
                                          alt="Imagen relacionada al servicio"
                                        />
                                      </InputFiles>
                                    </p>
                                  )}
                                </div>
                              }
                            />
                          </dd>
                        </div>

                        <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Sede
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <ActionHiddenInputGuard
                              typeModal={inModal}
                              childrenEdit={
                                <p>
                                  {service.cellar ? service.cellar.name : ""}
                                </p>
                              }
                              childrenAdd={
                                <SelectCellar
                                  cellarId={service.cellarId}
                                  /* onChangeCellar={(cellarId) =>
                                    onChangeValueCellar(indexService, cellarId)
                                  } */
                                  onChangeCellarItem={(item) =>
                                    onChangeValueCellarItem(indexService, item)
                                  }
                                />
                              }
                            />
                          </dd>
                        </div>

                        <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Equipo
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {service.cellarId != null ? (
                              <ActionHiddenInputGuard
                                typeModal={inModal}
                                childrenEdit={
                                  <p>
                                    {service.product
                                      ? service.product.name
                                      : ""}
                                  </p>
                                }
                                childrenAdd={
                                  <SelectProduct
                                    productId={services.productId}
                                    /* onChangeProduct={(productId) =>
                                      onChangeValueProduct(
                                        indexService,
                                        productId
                                      )
                                    } */
                                    onChangeProductItem={(item) =>
                                      onChangeValueProductItem(indexService, item)
                                    }
                                    cellarId={service.cellarId}
                                  />
                                }
                              />
                            ) : (
                              <p className="font-light italic">
                                Selecciona una sede.
                              </p>
                            )}
                          </dd>
                        </div>

                        <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Correos
                          </dt>

                          {/* Un input en funcionamiento */}
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <ActionHiddenInputGuard
                              typeModal={inModal}
                              childrenEdit={
                                service.emails ? (
                                  service.emails.map((email) => (
                                    <div className="badge bg-slate-500 border-none rounded text-slate-50 my-1 flex">
                                      <p>{email}</p>
                                    </div>
                                  ))
                                ) : (
                                  <p>No hay correos para mostrar</p>
                                )
                              }
                              childrenAdd={
                                <>
                                  {service.emails ? (
                                    <>
                                      {service.emails.map((email, iEmail) => (
                                        <>
                                          <div className="my-2">
                                            <TextInput
                                              value={email}
                                              onChangeValue={(e) => {
                                                onChangeEmail(
                                                  e.target.value,
                                                  indexService,
                                                  iEmail
                                                );
                                                setValidations({
                                                  ...validations,
                                                  email: checkEmail(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              error={LENGTH_ERROR}
                                              isValidated={validations.email}
                                            />
                                          </div>
                                          <button
                                            className="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded flex align-middle"
                                            type="submit"
                                            onClick={() =>
                                              onDeleteEmail(
                                                iEmail,
                                                indexService
                                              )
                                            }
                                          >
                                            <span class="material-symbols-outlined">
                                              delete
                                            </span>
                                            Eliminar
                                          </button>
                                        </>
                                      ))}

                                      {service.emails.length < 5 && (
                                        <button
                                          className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded my-1 flex align-middle"
                                          type="submit"
                                          onClick={() =>
                                            onClickAddEmailInput(indexService)
                                          }
                                        >
                                          <span class="material-symbols-outlined">
                                            add
                                          </span>
                                          Agregar correo
                                        </button>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded my-1 flex align-middle"
                                        type="submit"
                                        onClick={() =>
                                          onClickAddEmailInput(indexService)
                                        }
                                      >
                                        <span class="material-symbols-outlined">
                                          add
                                        </span>
                                        Agregar correo
                                      </button>
                                    </>
                                  )}
                                </>
                              }
                            />
                          </dd>
                        </div>

                        <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Fecha del servicio
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <ActionHiddenInputGuard
                              typeModal={inModal}
                              childrenEdit={<p>{service.date}</p>}
                              childrenAdd={
                                <TextInput
                                  type="date"
                                  value={service.date}
                                  onChangeValue={(e) => {
                                    onChangeDate(indexService, e.target.value);
                                    setValidations({
                                      ...validations,
                                      date: checkLength(e.target.value),
                                    });
                                  }}
                                  error={LENGTH_ERROR}
                                  isValidated={validations.date}
                                  maxDate={true}
                                />
                              }
                            />
                          </dd>
                        </div>

                        <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Estado
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <ActionHiddenInputGuard
                              typeModal={inModal}
                              childrenEdit={
                                <SelectStatus
                                  statusId={service.statusId}
                                  onChangeStatus={(statusId) =>
                                    onChangeStatus(indexService, statusId)
                                  }
                                />
                              }
                              childrenAdd={<p>{service.statusId}</p>}
                            />
                          </dd>
                        </div>
                      </div>
                    </div>

                    <div className="w-2/6">
                      {service.logs &&
                        Object.entries(JSON.parse(service.logs)).map(
                          ([key, item]) => (
                            <div className="p-4 bg-white text-sm">
                              <StatusField
                                statusId={key}
                                children={<p>{key}</p>}
                              />
                              <div className="flex">
                                <p className="font-semibold mr-2">
                                  Creado por:
                                </p>
                                {item.createdBy}
                              </div>
                              <div className="flex">
                                <p className="font-semibold mr-2">Fecha:</p>
                                <Moment locale="co" format="YYYY-MM-DD HH:mm">
                                  {new Date(item.date)}
                                </Moment>
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>

      <div className="bg-white p-6 shadow-sm">
        <div className="my-5">
          <ActionHiddenInputGuard
            typeModal={inModal}
            childrenEdit={""}
            childrenAdd={
              <IconTextButton
                icon="add"
                text="Agregar nuevo servicio"
                onClickButton={onClickAddService}
              />
            }
          />
        </div>
      </div>
    </>
  );
};

export default RequestForm;
