import { gql } from "@apollo/client";

export const LISTLOANS = gql`
  query ListLoans($companyId: String, $cellarId: String) {
    listLoans(companyId: $companyId, cellarId: $cellarId) {
      id
      cellarTool {
        name
      }
      cellarToolId
      cellar {
        name
      }
      cellarId
      tool {
        name
      }
      toolId
      companyId
      emails
      finishDate
      description
      creationDate
      createdBy
      userNameCreatedBy
      userName
      status
      logs
    }
  }
`;
