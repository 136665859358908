import React from "react";
import { PDFButton } from "../../../../ui/buttons/PDFButton";
import ImageModal from "../../../../ui/modals/ImageModal";
import HistoricService from "../pages/HistoricService";

const ToolDetail = ({ tool }) => {
  return (
    <>
      <div class="overflow-hidden bg-white shadow sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6 bg-slate-200">
          <div className="md:flex">
            <div className="w-full h-fit font-bold text-lg">
              {tool.name}
            </div>
          </div>
        </div>

        <div class="mt-3">
          <dl>
            <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Imagen</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 md:flex">
                {tool.imgURL ? (
                  <ImageModal url={tool.imgURL} name={tool.name} />
                ) : (
                  <p className="font-light italic">No hay imagen disponible.</p>
                )}
              </dd>
            </div>

            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Documentos</dt>
              <div className="mt-1 flex flex-col">
                {tool.documentsURL ? (
                  <>
                    {tool.documentsURL.map((item, i) => (
                      <PDFButton key={i} item={item} />
                    ))}
                  </>
                ) : (
                  <p className="font-light italic">No hay imagen disponible.</p>
                )}
              </div>
            </div>

            <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Descripción</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {tool.description}
              </dd>
            </div>

            <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Factura</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {tool.invoice}
              </dd>
            </div>

            <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Marca</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {tool.mark}
              </dd>
            </div>

            <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Referencia</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {tool.reference}
              </dd>
            </div>

            <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Serial</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {tool.serial}
              </dd>
            </div>

            <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Sede</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {tool.cellar.name}
              </dd>
            </div>

            <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Multimedia</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <a
                  href={tool.multimediaURL}
                  className="text-blue-500 tooltip contents break-words"
                  data-tip="Ir al enlace"
                  target="_blank"
                  rel="noreferrer"
                >
                  {tool.multimediaURL}
                </a>
              </dd>
            </div>
            <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Precio</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {tool.price}
              </dd>
            </div>
            <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Stock</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {tool.stock}
              </dd>
            </div>
            {/* <div className="bg-white p-6 rounded-lg shadow-sm">
              <div className="grid">
                <HistoricService tool={tool} />
              </div>

              <div
                id="info"
                className="grid md-grid-cols-3 px-4 gap-3 sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3"
              ></div>
            </div> */}
          </dl>
        </div>
      </div>
    </>
  );
};
export default ToolDetail;
