import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startInModal } from "../../../../../store/tab/thunks";
import { useMutation } from "@apollo/client";
import { UPDATECELLARS } from "../../../../../graphql/mutations/cellar/updateCellar";
import { LISTCELLARS } from "../../../../../graphql/queries/cellar/listCellars";
import IconTextButton from "../../../../ui/buttons/IconTextButton";
import ModalTemplate from "../../../../ui/modals/ModalTemplate";
import successToast from "../../../../../utils/toast/successToast";
import errorToast from "../../../../../utils/toast/errorToast";
import { toast } from "react-toastify";
import ZoneForm from "../layout/ZoneForm";
import ZONE_VALIDATIONS from "../helpers/validations";

function EditZone() {
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.auth);
  const { selectedElement } = useSelector((state) => state.tab);
  const [zone, setZone] = useState(selectedElement.row);
  const [validations, setValidations] = useState(ZONE_VALIDATIONS);

  const [updateCellarMutationFunction, { loading }] = useMutation(
    UPDATECELLARS,
    {
      refetchQueries: [{ query: LISTCELLARS }, "ListCellars"],
    }
  );

  const onClickEdit = async () => {
    await updateCellarMutationFunction({
      variables: {
        cellar: {
          name: zone.name,
          companyId: company.id,
        },
        id: selectedElement.row.id,
      },
    })
      .then(() => {
        toast.success("Sede actualizada", successToast);
        dispatch(startInModal({ inModal: null }));
      })
      .catch((error) => {
        toast.error(error.toString(), errorToast);
      });
  };

  return (
    <>
      <ModalTemplate
        title={"Editar sede"}
        onClickBack={() => {
          dispatch(startInModal({ inModal: null }));
        }}
      >
        <>
          <ZoneForm
            zone={zone}
            setZone={setZone}
            name={"sede"}
            validations={validations}
            setValidations={setValidations}
          />

          <div className="my-4 bg-white place-content-end flex p-4 rounded-lg shadow-sm">
            <IconTextButton
              icon="edit"
              text="Actualizar"
              onClickButton={onClickEdit}
              loading={loading}
            />
          </div>
        </>
      </ModalTemplate>
    </>
  );
}

export default EditZone;
