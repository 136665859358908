import React from 'react'

export const AlertMessageWindow = ({text, bgcolor, valuebgcolor, textcolor, valuebgtext}) => {
  return (
    <div className={`alert bg-${bgcolor}-${valuebgcolor} rounded-lg py-3 px-4 text-base text-${textcolor}-${valuebgtext} inline-flex items-center w-full alert-dismissible fade show`} role="alert">
        {text}
        <button type="button" className={`btn-close box-content w-4 h-4 p-1 ml-auto text-${textcolor}-${valuebgtext} border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-${textcolor}-${valuebgtext} hover:opacity-75 hover:no-underline`} data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
  )
}
