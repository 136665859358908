import TableTitleGoTo from "../../../../ui/table/titles/TableTitleGoTo";
import TableTitleImage from "../../../../ui/table/titles/TableTitleImage";

export const toolTableColumns = [
  {
    Header: " ",
    columns: [
      /* {
        Header: " ",
      }, */
      {
        Header: "Foto",
        accessor: "imgURL",
        Cell: TableTitleImage,
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "Herramienta",
        accessor: "name",
      },
      {
        Header: "Descripción",
        accessor: "description",
      },
      {
        Header: "Factura",
        accessor: "invoice",
      },
      {
        Header: "Marca",
        accessor: "mark",
      },
      {
        Header: "Referencia",
        accessor: "reference",
      },
      {
        Header: "Serial",
        accessor: "serial",
      },
      {
        Header: "Precio",
        accessor: "price",
      },
      {
        Header: "Stock",
        accessor: "stock",
      },
      {
        Header: "Sedes",
        accessor: "cellar.name",
      },

      {
        Header: "Acción",
        accessor: "moreInfo",
        Cell: TableTitleGoTo,
        disableSortBy: true,
        disableFilters: true,
      },
    ],
  },
];
