import {
  setSelectedElement,
  setInModal,
  setComponent,
  setDataCRUD,
} from "./tabSlice";

export const startInModal = ({ inModal }) => {
  return async (dispatch) => {
    const resp = { inModal };
    /* console.log(resp); */
    dispatch(setInModal(resp));
  };
};

export const startSelectedElement = ({ row }) => {
  return async (dispatch) => {
    const resp = { row };
    /* console.log("resp", resp) */
    dispatch(setSelectedElement(resp));
  };
};

export const startComponent = ({ component }) => {
  return async (dispatch) => {
    const resp = { component };
    /* console.log(resp) */
    dispatch(setComponent(resp));
  };
};

export const startDataCRUD = ({ dataResponse }) => {
  return async (dispatch) => {
    const resp = { dataResponse };
    /* console.log(resp) */
    dispatch(setDataCRUD(resp));
  };
};