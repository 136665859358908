import { useQuery } from "@apollo/client";
import React from "react";
import { useSelector } from "react-redux";
import { LISTROLES } from "../../../../../../graphql/queries/cellar/listRoles";

const SelectRole = ({ roleId, onChangeRole }) => {

  const { data, loading } = useQuery(LISTROLES);
  console.log("datarol", data)
  const auth = useSelector((state) => state.auth);

  if (loading) return <p>Cargando...</p>;

  return (
    <>
      {data && data.listRoles && (
        <select
          className="select w-4/5"
          onChange={(e) => {
            onChangeRole(e.target.value);
          }}
          value={roleId}
        >
          <option disabled selected>
            Selecciona un rol
          </option>

          {data.listRoles.map((item) => {

            if (auth.roleId === 'operator') {
              if (item.id === 'guest' || item.id === 'provider') {
                return (<option className="display-inline" key={item.id} value={item.id}>
                  {item.name}
                </option>)
              }
            }
            else {
              return (<option className="display-inline" key={item.id} value={item.id}>
                {item.name}
              </option>
              )
            }

          })}
        </select>
      )}
    </>
  );
};

export default SelectRole;
