import checkLength from "../../../../../utils/checkLength";

export default function checkZoneFields(zone, validations) {
  const resp = {
    valid: true,
    toastText: "ERROR: Verifica los campos ingresados",
    toastInfo: {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    },
  };

  validations.name = checkLength(zone.name);

  if (!validations.name) {
    resp.valid = false;
  }

  resp.newValidations = validations;

  return resp;
}
