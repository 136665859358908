import { gql } from "@apollo/client";

export const CONFIRMFORGOTPASSWORD = gql`
  mutation ConfirmForgotPassword(
    $userId: String!
    $verificationCode: String
    $newPassword: String
  ) {
    confirmForgotPassword(
      userId: $userId
      verificationCode: $verificationCode
      newPassword: $newPassword
    )
  }
`;
