import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { PrivateRoutes } from "../router/routes";

export const RoleGuard = ({ role }) => {
  console.log("role", role)
  return role === "admin" || role === "operator" /* || role === "provider"  */? (
    <Outlet />
  ) : (
    <Navigate replace to={`./${PrivateRoutes.HOME}/${PrivateRoutes.PRODUCT}`} />
  );
};

export const RoleGuardZone = ({ role }) => {
  return role === "admin" ? (
    <Outlet />
  ) : (
    <Navigate replace to={`./${PrivateRoutes.HOME}/${PrivateRoutes.ADMIN}/${PrivateRoutes.USER}`} />
  );
};