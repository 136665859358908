import checkLength from "../../../../../utils/checkLength";

export default function checkToolFields(tool, validations) {
  const resp = {
    valid: true,
    toastText: "ERROR: Verifica los campos ingresados",
    toastInfo: {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    },
  };

  validations.description = checkLength(tool.description);
  validations.cellarId = checkLength(tool.cellarId);

  
  if (!validations.cellarId || !validations.description) {
    resp.valid = false;
  }

  resp.newValidations = validations;

  return resp;
}
