import React, { useMemo, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { LISTCELLARS } from "../../../../../graphql/queries/cellar/listCellars";
import { cellarTableColumns } from "./zoneTableColumns";
import AddZone from "../pages/AddZone";
import EditZone from "../pages/EditZone";
import {
  startComponent,
  startDataCRUD,
} from "../../../../../store/tab/thunks";
import TabLayout from "../../shared/TabLayout";
import ViewZone from "../pages/ViewZone";
import Loading from "../../../../ui/loading/Loading";

const Zone = () => {
  const { company } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const { dataResponse } = useSelector((state) => state.tab);

  const { data, loading } = useQuery(LISTCELLARS, {
    variables: { companyId: company.id },
    onCompleted: (data) => {
      dispatch(
        startDataCRUD({
          dataResponse: data.listCellars,
        })
      );
    },
  });

  const columns = useMemo(() => cellarTableColumns, []);

  const range = (len) => {
    const arr = [];
    for (let i = 0; i < len; i++) {
      arr.push(i);
    }
    return arr;
  };

  const newCellar = () => {
    return {
      name: "example",
    };
  };

/*   const makeData = (...lens) => {
    const makeDataLevel = (depth = 0) => {
      const len = lens[depth];
      return range(len).map((d) => {
        return {
          ...newCellar(),
          subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
        };
      });
    };
    return makeDataLevel();
  };
  const serverData = makeData(1000);

  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0); */

  // const fetchData = useCallback(
  //   ({ pageSize, pageIndex, sortBy, filters, groupBy }) => {
  //     const fetchId = ++fetchIdRef.current;
  //     //setLoading(true);

  //     // We'll even set a delay to simulate a server here
  //     setTimeout(() => {
  //       // Only update the data if this is the latest fetch
  //       if (fetchId === fetchIdRef.current) {
  //         /* console.log("pageIndex", pageIndex); */
  //         const startRow = pageSize * pageIndex;
  //         const endRow = startRow + pageSize;
  //         //data.slice(startRow, endRow);

  //         // Your server could send back total page count.
  //         // For now we'll just fake it, too
  //         setPageCount(Math.ceil(serverData.length / pageSize));

  //         /* setLoading(false); */
  //       }
  //     }, 1000);
  //   },
  //   []
  // );

  dispatch(startComponent({ component: "Zone" }));
  if (loading) return <Loading />;

  return (
    <>
      <TabLayout
        titleComponent={"Sedes"}
        AddElement={AddZone}
        EditElement={EditZone}
        ViewElement={ViewZone}
        nameElement={"sede"}
        data={data}
        dataQuery={dataResponse}
        columns={columns}
        // fetchData={fetchData}
        loading={loading}
        // pageCount={pageCount}
        hasDownloadBtn={true}
      />
    </>
  );
};

export default Zone;
