export const ActionHiddenInputGuard = ({
  typeModal,
  childrenEdit,
  childrenAdd,
}) => {
  if (typeModal === "edit") {
    return childrenEdit;
  } else {
    return childrenAdd;
  }
};