import TableTitleGoTo from "../../../../ui/table/titles/TableTitleGoTo";

export const cellarTableColumns = [
  {
    Header: " ",
    columns: [
      /* {
        Header: " ",
      }, */
      {
        Header: "Nombre de sede",
        accessor: "name",
      },
      {
        Header: "Acción",
        accessor: "moreInfo",
        Cell: TableTitleGoTo,
        disableSortBy: true,
        disableFilters: true,
      },
    ],
  },
];
