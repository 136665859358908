const LOAN_VALIDATIONS = {
  name: null,
  description: null,
  companyId: null,
  emails: null,
  createdBy: null,
  userName: null,
  userNameCreatedBy: null,
  status: null,
  creationDate: true,
  finishDate: true,
};

export default LOAN_VALIDATIONS;
