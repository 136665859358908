import { gql } from "@apollo/client";

export const LISTUSERS = gql`
  query ListUsers($companyId: String!) {
    listUsers(companyId: $companyId) {
      id
      name
      phone_number
      companies
      disabled
      config {
        appVersion
      }
      cellar{
        name
      }
      companyId
      company {
        id
        name
        creationDate
      }
      roleId
      role {
        id
        name
      }
      creationDate
      imgURL
      cellarId
    }
  }
`;
