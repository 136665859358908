import React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { startInModal } from "../../../store/tab/thunks";

const NavlinkItem = ({ route, icon, name, isActive = false }) => {
  const dispatch = useDispatch();
  dispatch(startInModal({ inModal: null }));

  return (
    <NavLink
      className={`cursor-pointer flex items-center font-medium text-sm p-2 px-4 ${
        isActive && "bg-dark text-textLight"
      }`}
      to={route}
    >
      <img
        className="w-10"
        src={
          isActive
            ? `/images/icons/${icon}_blanco.png`
            : `/images/icons/${icon}_negro.png`
        }
        alt={"Ícono"}
      />
      <div className="pl-3">{name}</div>
    </NavLink>
  );
};

export default NavlinkItem;
