import { ChangeEvent, HTMLInputElement, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { CREATEPRODUCTSS } from "../../../../../graphql/mutations/products/createProducts";
import { useSelector } from "react-redux";
import { LISTCELLARS } from "../../../../../graphql/queries/cellar/listCellars";
import { useQuery } from "@apollo/client";
import successToast from "../../../../../utils/toast/successToast";
import { toast } from "react-toastify";
import errorToast from "../../../../../utils/toast/errorToast";
import InputFileUpload from "../../../../ui/buttons/InputFileUpload";
import { CREATETOOLSS } from "../../../../../graphql/mutations/tool/createTools";
function UploadTools() {
  const [createToolsMutationFunction, { loading }] =
    useMutation(CREATETOOLSS);
  const { company } = useSelector((state) => state.auth);

  const { data: dataCellars, loading: loadingCellars } = useQuery(LISTCELLARS, {
    variables: { companyId: company.id },
  });

  const [loadingInsert, setLoadingInsert] = useState(false);

  const headers = [
    "sede",
    "nombre",
    "descripcion",
    "factura",
    "serial",
    "marca",
    "stock",
    "precio",
  //  "depreciacion",
    "referencia",
  ];

  useEffect(() => {
    console.log("dataCellars", dataCellars);
  }, [dataCellars]);

  const processResults = (results) => {
    const _results = [];

    results.map((result) => {
      console.log("result", result)
      const cellarByName = dataCellars.listCellars.find(
        (cellar) =>
          cellar.name.trim().toLowerCase() == result.sede.trim().toLowerCase()
      );

      if (cellarByName) {
        console.log("cellarByNameeeeeeeee!")
        const _item = {
          companyId: company.id,
          // "id": 1664321777723,
          cellarId: cellarByName.id,
          description: result.descripcion.trim(),
          documentsURL: [],
          invoice: result.factura,
        //  isApproved: true,
        isApproved: false,
          mark: result.marca,
          multimediaURL: [],
          name: result.nombre,
          price: result.precio,
          reference: result.referencia,
     //     sellingPrice: null,
          serial: result.serial,
          stock: parseInt(result.stock.trim()),
       //   depreciation: result.depreciacion,
        };

        _results.push(_item);
      }
    });

    return _results;
  };

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  /*
  const uploadProducts = async (chunks) => {
    console.log("uploadProducts");
    console.log("chunks", chunks);
    setLoadingInsert(true);
    const bulkProductsPromises = chunks.map(async (input) => {
      await timeout(3000);

      await createToolsMutationFunction({
        variables: {
          input,
        },
      })
        .then((data) => {
          toast.success("Subida de archivo exitosa.", successToast);
          console.log(data);
        })
        .catch((error) => {
          toast.error("Ocurrió un error en la subida.", errorToast);
          console.log(error);
        });
    });

    await Promise.all(bulkProductsPromises);

    setLoadingInsert(false);
  };*/
  const uploadProducts = async (chunks) => {
    console.log("uploadProducts");
    console.log("chunks", chunks);
    setLoadingInsert(true);
    const bulkProductsPromises = chunks.map(async (input, index) => {
     // await timeout(6000);
    
      return new Promise(resolve => {
        setTimeout( async() => {
        
          console.log("timeoput!!!")
      await   createToolsMutationFunction({
        variables: {
          input,
        },
      })
      resolve(true)
    }, index* 8000);
  });
       /* .then((data) => {
          toast.success("Subida de archivo exitosa.", successToast);
          console.log(data);
        })
        .catch((error) => {
          toast.error("Ocurrió un error en la subida.", errorToast);
          console.log(error);
        });
        */
    });

     Promise.all(bulkProductsPromises);

    setLoadingInsert(false);
  };
  const csvJSON = (csv) => {
    var lines = csv.split("\n");

    var result = [];
    var headers = lines[0].trim().split(",");

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }

    //return result; //JavaScript object
    //return JSON.stringify(result); //JSON
    console.log("result", result);
    return {
      result,
      headers,
    };
  };

  const onSelectedFile = async (oFile) => {
    console.log(oFile);
    var oFReader = new FileReader();
    oFReader.onloadend = async function (res) {
      // var res = result;

      console.log("RES", res);
      const _json = csvJSON(res.target.result);
      //     var json = mythis.tsvJSON(res);
      var json = _json.headers;
      var cont = true;

      var jsonPassed = true;
      if (json.length == headers.length) {
        json.map((item, index) => {
          console.log(item);
          console.log(headers[index]);
          if (item.trim() == headers[index]) {
          } else {
            //  mythis.uploadRoutesLog('Error en la columna :' + item + ',el archivo  debe contener los siguientes campos con el orden: Cent, Client, Nombre Client, Direccion Client, Municipio, Barrio, Coord. X, Coord. Y, Ruta de reparto, Secuencia y Fecha' + ". Nombre del Archivo: " + newName)
            toast.error(
              "Error en la columna :" +
                item +
                ",el archivo  debe contener los siguientes campos con el orden: sede, nombre, descripcion, factura, serial, marca, stock, precio, referencia",
              errorToast
            );
            cont = false;
            jsonPassed = false;
          }
        });
      } else if (json.length < headers.length) {
        //    mythis.uploadRoutesLog('La cantidad de columnas es menor a las solicitadas,el archivo  debe contener los siguientes campos: Cent, Client, Nombre Client, Direccion Client, Municipio, Barrio, Coord. X, Coord. Y, Ruta de reparto, Secuencia y Fecha' + ". Nombre del Archivo: " + newName)
        toast.error(
          "La cantidad de columnas es menor a las solicitadas,el archivo  debe contener los siguientes campos: sede, nombre, descripcion, factura, serial, marca, stock, precio, referencia",
          errorToast
        );
        cont = false;
        jsonPassed = false;
      } else if (json.length > headers.length) {
        //  mythis.uploadRoutesLog('La cantidad de columnas es mayor a las solicitadas,el archivo  debe contener los siguientes campos: Cent, Client, Nombre Client, Direccion Client, Municipio, Barrio, Coord. X, Coord. Y, Ruta de reparto, Secuencia y Fecha' + ". Nombre del Archivo: " + newName)
        toast.error(
          "La cantidad de columnas es mayor a las solicitadas,el archivo  debe contener los siguientes campos:sede, nombre, descripcion, factura, serial, marca, stock, precio, referencia",
          errorToast
        );
        cont = false;
        jsonPassed = false;
      }

      if (jsonPassed) {
        //  CHUNKS

        const chunkSize = 20;
        const chunks = [];

        console.log("JSONNN!", _json)

        const results = processResults(_json.result);

        for (let i = 0; i < results.length; i += chunkSize) {
          const chunk = results.slice(i, i + chunkSize);
          chunks.push(chunk);
          // do whatever
        }
        uploadProducts(chunks);
      }

      console.log("readeeeer json");
      console.log(json);
    };
    oFReader.readAsText(oFile);
  };

  return (
    <div className="p-8 h-full text-center">
      {loadingCellars && <div>Cargando</div>}
      <div className="title-cont">
        <p className="font-bold text-3xl">Subida masiva de herramientas</p>
      </div>

      {loadingInsert && (
        <div className="flex items-center justify-center my-4">
          <div role="status">
            <svg
              aria-hidden="true"
              class="w-8 h-8 mr-2 text-gray-200 animate-spin-fast dark:text-gray-600 fill-amber-500"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
          Insertando herramientas, no cierres la ventana, espera por favor...
        </div>
      )}

      {!loadingCellars && (
        <>
          <div className="h-[70vh] mt-5">
            <p>Selecciona el archivo:</p>

            <p className="text-red-600 font-semibold">
              *Formato permitido: csv
            </p>
            <div className="flex justify-center items-center h-[60%]">
              <InputFileUpload id={"csvUpload"} onChangeFile={onSelectedFile}>
                <img
                  src="/images/addImage.jpg"
                  className="cursor-pointer m-2 w-[50%]"
                  alt="Imagen de subida de archivos"
                />
              </InputFileUpload>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default UploadTools;
