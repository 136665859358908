import React, { useState } from "react";
import { toast } from "react-toastify";

const InputDocument = ({ children, id, onChangeFile }) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const allowedTypes = [
      "application/pdf",
      "application/vnd.ms-excel",
      "text/csv",
      "text/plain",
      "application/json",
      "application/msword",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ];

    const files = e.dataTransfer.files;
    const invalidFiles = Array.from(files).filter(file => !allowedTypes.includes(file.type));

    if (invalidFiles.length > 0) {
      toast.error("Debes subir el tipo de archivo permitido.");
      return;
    }

    onChangeFile({ filelist: files });
  };

  return (
    <>
      <div
        className={`${
          isDragging ? "border-blue-500 bg-blue-100" : ""
        } border-dashed border-2 rounded-md p-4`}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          accept="
            application/pdf, 
            application/vnd.ms-excel, 
            text/csv, 
            text/plain,
            application/json,
            application/msword,
            application/vnd.ms-powerpoint,
            application/vnd.openxmlformats-officedocument.wordprocessingml.document,
            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
            application/vnd.openxmlformats-officedocument.presentationml.presentation
          "
          type="file"
          id={id ? id : "id"}
          style={{ display: "none" }}
          multiple
          onChange={(e) => onChangeFile({ filelist: e.target.files })}
        />
        <label className="flex justify-center" htmlFor={id ? id : "id"}>
          {children}
        </label>
      </div>
    </>
  );
};

export default InputDocument;
