import { gql } from "@apollo/client";

export const LISTTOOLS = gql`
  query ListTools(
    $companyId: String!
    $cellarId: String
    $isApproved: Boolean
  ) {
    listTools(
      companyId: $companyId
      cellarId: $cellarId
      isApproved: $isApproved
    ) {
      id
      name
      description
      reference
      companyId
      cellar{
        id
        name
      }
      cellarId
      stock
      price
      creationDate
      isApproved
      documentsURL{
        name
        url
      }
      multimediaURL
      imgURL
      invoice
      mark
      serial
    }
  }
`;
