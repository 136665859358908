import React from "react";
import { Outlet } from "react-router-dom";

const Admin = () => {
  return (
    <div className="w-full h-screen bg-slate-50">
      <div className="">
        <Outlet />
      </div>
    </div>
  );
};

export default Admin;
