import { gql } from "@apollo/client";

export const LISTROLES = gql`
  query ListRoles {
    listRoles {
      name
      id
    }
  }
`;
