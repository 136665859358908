import React from "react";

const ZoneDetail = ({ zone }) => {
  return (
    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6 bg-slate-200">
          <div className="md:flex">
            <div className="w-full h-fit font-bold text-lg">{zone.name}</div>
          </div>
        </div>
</div>
  );
};
export default ZoneDetail;
