export const deleteImage = (image, setElement, element) => {
  if (image !== null) {
    return setElement({
      ...element,
      imgURL: "",
      imgBlob: null,
      imageType: null,
    });
  }
};
