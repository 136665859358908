import React, { useEffect } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
} from "react-table";
import IconButton from "../buttons/IconButton";
import { downloadToCSV } from "./downloadToCSV";
import DefaultColumnFilter from "./filters/DefaultColumnFilter";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <input className="checkbox" type="checkbox" ref={resolvedRef} {...rest} />
    );
  }
);

function Table({
  titleComponent,
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  hasDownloadBtn,
  topContent,
  nameReport,
  onClickItem,
}) {
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    allColumns,
    getToggleHideAllColumnsProps,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    rows,
    // Get the state from the instance
    state: { pageIndex, pageSize, sortBy, filters, groupBy },
  } = useTable(
    {
      columns,
      data,

      defaultColumn, // Be sure to pass the defaultColumn option

      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      // manualPagination: true, // Tell the usePagination
      // pageCount: controlledPageCount,
      // autoResetPage: false,

      //manualSortBy: true,
      //manualFilters: true,
    },

    useFilters,
    useGlobalFilter,

    useSortBy,
    usePagination
  );

  useEffect(() => {
    /* console.log("sortBy", sortBy); */
  }, [sortBy]);

  useEffect(() => {
    /* console.log("filters", filters); */
  }, [filters]);

  // Listen for changes in pagination and use the state to fetch our new data
  /* React.useEffect(() => {
    fetchData({ pageIndex, pageSize, sortBy, filters, groupBy })
  }, [fetchData, pageIndex, pageSize]) */

  /*  console.log("all", allColumns); */

  const downloadCSV = () => {
     console.log("columns", columns);
    console.log("data", data);
    console.log("all", allColumns);
    console.log("rows",rows); 

    rows.map( (row)=> {
      prepareRow(row);

    })

    const newColumns = [];

    allColumns.map((item) => {
      if (item.isVisible) {
        newColumns.push(item);
      }
    });
    downloadToCSV(newColumns, rows, nameReport);
  };

  // Render the UI for your table
  return (
    <>
      <div className="md:flex md:justify-between items-center">
        <div className="title-cont">
          <p className="font-bold text-3xl">{titleComponent}</p>
        </div>

        <div className="flex items-center">
          <div className="collapse relative mx-2 rounded-lg hover:bg-dark hover:text-textLight ">
            <input type="checkbox" className="peer" />
            <div className="collapse-title border border-dark text-center rounded-lg flex items-center justify-center px-4 uppercase text-bold text-sm  ">
              <div>Editar Campos visibles</div>
            </div>
            <div className="collapse-content fixed bg-slate-50 z-20 mt-16 shadow-md px ">
              <div className="pt-8">
                <div className="text-textDark">
                  <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />{" "}
                  Alternar todo
                </div>
                <div className="  p-4">
                  {allColumns.map((column) => (
                    <div key={column.id} className="mx-2 flex text-center ">
                      <label className="label cursor-pointer">
                        <input
                          type="checkbox"
                          checked="checked"
                          className="checkbox"
                          {...column.getToggleHiddenProps()}
                        />
                        <span className="label-text pl-3">
                          {" "}
                          {column.Header}
                        </span>
                      </label>
                    </div>
                  ))}
                </div>
                <br />
              </div>
            </div>
          </div>

          {topContent && topContent}

          {hasDownloadBtn && (
            <IconButton
              icon="download"
              tooltip="Exportar datos"
              onClickIconButton={downloadCSV}
            />
          )}
        </div>
      </div>

      <table className="table w-full border-lg" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="!bg-transparent align-baseline d-none"
                  >
                    <div className="flex py-4 items-center mt-5">
                      <div className="text-sm text-slate-700  px-2 ">
                        {column.render("Header")}
                      </div>
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <span class="material-symbols-outlined">
                              arrow_downward
                            </span>
                          ) : (
                            <span class="material-symbols-outlined">
                              arrow_upward
                            </span>
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                    {/* Render the columns filter UI */}

                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);

            return (
              <tr {...row.getRowProps()} className="hover text-sm font-normal">
                {row.cells.map((cell) => {
                  return (
                    <td
                      className="w-30 whitespace-normal"
                      /* onClick={() => {onClickItem(cell)}} */ {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          <tr>
            {loading ? (
              // Use our custom loading state to show a loading indicator
              /*  <td colSpan="10000">Loading...</td> */
              <td colSpan="10000">
                <div className="p-4">
                  <button
                    type="button"
                    className="  flex text-lg font-bold"
                    disabled
                  >
                    <svg
                      className="animate-spin h-5 w-5 mr-3 bg-slate-800  "
                      viewBox="0 0 24 24"
                    ></svg>
                    Cargando...
                  </button>
                </div>
              </td>
            ) : (
              <td colSpan="10000" className="text-sm font-bold">
                {/* Mostrando {page.length} de ~{controlledPageCount * pageSize}{" "}
                resultados*/}
              </td>
            )}
          </tr>
        </tbody>
      </table>
      {/* 
          Pagination can be built however you'd like. 
          This is just a very basic UI implementation:
        */}
      <div className="pagination">
        <button
          className="btn btn-square"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {"<<"}
        </button>{" "}
        <button
          className="btn btn-square"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"<"}
        </button>{" "}
        <button
          className="btn btn-square"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {">"}
        </button>{" "}
        <button
          className="btn btn-square"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </button>{" "}
        <span>
          Página{" "}
          <strong>
            {pageIndex + 1} de {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Ir a la página:{" "}
          <input
            className="input input-bordered w-full max-w-xs"
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          className="select select-bordered w-full max-w-xs"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Mostrar {pageSize} items
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
export default Table;
