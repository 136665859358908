import React from "react";

const SelectStatus = ({ statusId, onChangeStatus }) => {
  const status = [
    { id: 0, name: "ABIERTO" },
    { id: 1, name: "INICIADO" },
    { id: 2, name: "EN PROCESO" },
    { id: 3, name: "CERRADO" },
  ];

  return (
    <>
      <select
        className="select mb-4 w-1/2"
        onChange={(e) => {
          onChangeStatus(e.target.value);
        }}
        value={statusId}
      >
        <option disabled selected>
          Selecciona un estado
        </option>
        {status.map((item) => (
          <option className="display-inline" value={item.name} key={item.id}>
            {item.name}
          </option>
        ))}
      </select>
    </>
  );
};

export default SelectStatus;
