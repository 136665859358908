import React from "react";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { LISTTOOLS } from "../../../../../graphql/queries/tool/listTools";

const SelectTool = ({ toolId, onChangeTool, onChangeToolItem, cellarId }) => {
  const { company } = useSelector((state) => state.auth);

  const { data, loading, error } = useQuery(LISTTOOLS, {
    variables: { companyId: company.id, cellarId: cellarId, isApproved: false },
  });

  if (loading) return <p>Cargando...</p>;
  if (error) return console.log(error);

  return (
    <>
      {data && data.listTools && (
        <select
          className="select w-4/5 "
          onChange={(e) => {
            if (data.listTools && onChangeToolItem) {
              const item = data.listTools.find(
                (_item) => _item.id === e.target.value
              );
              if (item) onChangeToolItem(item);
            } else if (data.listTools && onChangeTool) {
              onChangeTool(e.target.value);
            }
          }}
          value={toolId}
        >
          <option disabled selected>
            Selecciona una herramienta
          </option>
          {data.listTools.map((item) => (
            <option className="display-inline" key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      )}
    </>
  );
};

export default SelectTool;
