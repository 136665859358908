import React from "react";

const statusStyles = {
  ABIERTO: "badge bg-lime-600 border-none rounded text-slate-50 my-1 flex",
  INICIADO: "badge bg-blue-500 border-none rounded text-slate-50 my-1 flex",
  "EN PROCESO": "badge bg-amber-400 border-none rounded text-slate-50 my-1 flex",
  ENTREGADO: "badge bg-lime-600 border-none rounded text-slate-50 my-1 flex",
  CERRADO: "badge bg-red-500 border-none rounded text-slate-50 my-1 flex",
  "EN ESPERA": "badge bg-sky-500 border-none rounded text-slate-50 my-1 flex",
  DEFAULT: "badge bg-gray-500 border-none rounded text-slate-50 my-1 flex",
};

const StatusField = ({ statusId, children }) => {
  const statusStyle = statusStyles[statusId] || statusStyles.DEFAULT;

  return <div className={statusStyle}>{children}</div>;
};

export default StatusField;
