import { gql } from "@apollo/client";

export const REGISTER = gql`
  mutation Register($user: UserInput!, $password: String!) {
    register(user: $user, password: $password) {
        id
        name
        imgURL
        roleId
        company {
            id
            name
            creationDate
        }
        role{
            id
            name
        }
    }
  }
`;
