import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startInModal } from "../../../../../store/tab/thunks";
import { useMutation } from "@apollo/client";
import IconTextButton from "../../../../ui/buttons/IconTextButton";
import ModalTemplate from "../../../../ui/modals/ModalTemplate";
import successToast from "../../../../../utils/toast/successToast";
import { toast } from "react-toastify";
import RequestForm from "../layout/RequestForm";
import { LISTORDERS } from "../../../../../graphql/queries/order/listOrders";
import REQUEST_VALIDATIONS from "../helpers/validations";
import { useEffect } from "react";
import { UPDATEORDERS } from "../../../../../graphql/mutations/order/updateOrder";
import checkRequestFields from "../helpers/checkRequestFields";
import { deleteTypename } from "../../../../../utils/deleteTypename";
import errorToast from "../../../../../utils/toast/errorToast";

function EditRequest() {
  const dispatch = useDispatch();
  const { company, id } = useSelector((state) => state.auth);
  const { selectedElement } = useSelector((state) => state.tab);

  const [updateRequestMutationFunction, { loading }] = useMutation(
    UPDATEORDERS,
    { refetchQueries: [{ query: LISTORDERS }, "ListOrders"] }
  );

  const serviceTypes = ["Servicio de garantía", "Revisión o mantenimiento"];

  const [order, setOrder] = useState(selectedElement.row);

  const [services, setServices] = useState(order.services);

  const [validations, setValidations] = useState(REQUEST_VALIDATIONS);

  const [editedServices, setEditedService] = useState([]);

  const onChangeService = (item) => {
    const serviceExist = editedServices.find(
      (service) => service.id == item.id
    );

    item = deleteTypename(item);

    if (item.imgsURL && item.imgsURL.length >= 1) {
      item.imgsURL = item.imgsURL.map((itemImg) => {
        return deleteTypename(itemImg);
      });
    }
    item = {
      id: item.id,
      cellarId: item.cellarId,
      cellarName: item.cellarName,
      description: item.description,
      serviceTypeId: item.serviceTypeId,
      creationDate: item.creationDate,
      date: item.date,
      imgsURL: item.imgsURL,
      productId: item.productId,
      productName: item.productName,
      statusId: item.statusId,
      emails: item.emails,
      logs: item.logs,
    };

    if (serviceExist) {
      let i = 0;
      editedServices.map((service, index) => {
        if (service.id == item.id) {
          i = index;
        }
      });
      editedServices[i] = item;
    }
    if (!serviceExist) {
      editedServices.push(item);
      setEditedService(editedServices);
    }
  };

  useEffect(() => {
    const arrServicesProps = [];
    services.map((i) => {
      arrServicesProps.push({
        cellarId: i.cellarId,
        cellarName: i.cellarName,
        description: i.description,
        serviceTypeId: i.serviceTypeId,
        creationDate: i.creationDate,
        date: i.date,
        imgsURL: i.imgsURL,
        productId: i.productId,
        productName: i.productName,
        statusId: i.statusId,
        emails: i.emails,

      });
    });
    setOrder({
      name: order.name,
      description: "",
      companyId: company.id,
      services: arrServicesProps,
    });
  }, [services]);

  const onClickEdit = async () => {
    const { valid, toastText, toastInfo, newValidations } = checkRequestFields(
      order,
      validations
    );

    var validSelect = true;
    if (order.name.length === 0) {
      validSelect = false;
    } else {
      services.map((item) => {
        if (item.description.length === 0 || item.statusId == null) {
          validSelect = false;
        }
      });
    }

    if (!valid) {
      toast.error(toastText, toastInfo);
      setValidations({ ...newValidations });
    } else {
      if (!validSelect) {
        const resp = {
          toastText: "ERROR: Verifica los campos requeridos del servicio.",
          toastInfo: {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        };
        toast.error(resp.toastText, resp.toastInfo);
      } else {
        setServices([...services]);

        const arrServicesProps = [];

        services.map((i) => {
          const imgURL = i.imgsURL.map((img) => {
            return deleteTypename(img);
          });

          return arrServicesProps.push({
            id: i.id,
            cellarId: i.cellarId,
            cellarName: i.cellarName,
            description: i.description,
            serviceTypeId: i.serviceTypeId,
            creationDate: i.creationDate,
            date: i.date,
            imgsURL: imgURL,
            productId: i.productId,
            productName: i.productName,
            statusId: i.statusId,
            emails: i.emails,
          });
        });

        updateOrder(arrServicesProps);
      }
    }
  };

  const updateOrder = async (servicesData) => {
    //  const newOrder = { ...order, services: servicesData };
    const newOrder = { ...order, services: editedServices };
    await updateRequestMutationFunction({
      variables: {
        order: newOrder,
        id: selectedElement.row.id,
        createdBy: id,
      },
    })
      .then((data) => {
        console.log(data);
        toast.success("Producto actualizado", successToast);
        dispatch(startInModal({ inModal: null }));
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.toString(), errorToast);
      });
  };

  return (
    <>
      <ModalTemplate
        title={"Editar solicitud"}
        onClickBack={() => {
          dispatch(startInModal({ inModal: null }));
        }}
      >
        <>
          <RequestForm
            onChangeService={onChangeService}
            order={order}
            setOrder={setOrder}
            services={services}
            setServices={setServices}
            serviceTypes={serviceTypes}
            name={"solicitud"}
            validations={validations}
            setValidations={setValidations}
          />

          <div className="my-4 bg-white place-content-end flex p-4 rounded-lg shadow-sm">
            <IconTextButton
              icon="done"
              text="Guardar"
              onClickButton={onClickEdit}
              loading={loading}
            />
          </div>
        </>
      </ModalTemplate>
    </>
  );
}

export default EditRequest;
