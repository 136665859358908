import React from "react";
import checkEmail from "../../../../../utils/checkEmail";
import checkLength from "../../../../../utils/checkLength";
import checkPass from "../../../../../utils/checkPass";
import { deleteImage } from "../../../../../utils/deleteImage";
import { resizeFile } from "../../../../../utils/resizeFile";
import SelectCellar from "../../../../home/components/shared/Inputs/SelectCellar";
import SelectOption from "../../../../home/components/shared/Inputs/SelectOption";
import InputFile from "../../../../ui/buttons/InputFile";
import TextInput from "../../../../ui/Inputs/TextInput";
import ImageModal from "../../../../ui/modals/ImageModal";
import {
  EMAIL_ERROR,
  LENGTH_ERROR,
  PASSWORD_ERROR,
} from "../helpers/inputErrors";
import SelectRole from "./Inputs/SelectRole";

const UserForm = ({
  user,
  setUser,
  validations,
  setValidations,
  hasPassword,
  hasEmail,
  hasEnabledUser,
}) => {
  const setImageURL = async (file) => {
    /* console.log("file", file); */
    if (file && file.size) {
      const newFile = await resizeFile(file, { maxWidth: 512, maxHeight: 512 });
      var _file = URL.createObjectURL(newFile);

      setUser({
        ...user,
        imgURL: _file,
        imgBlob: newFile,
        imageType: file.type,
      });
    }
  };

  return (
    <>
      <div class="overflow-hidden bg-white shadow sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6 bg-slate-200">
          <div className="md:flex">
            <TextInput
              title={"Nombre"}
              value={user.name}
              onChangeValue={(e) => {
                setUser({ ...user, name: e.target.value });
                setValidations({
                  ...validations,
                  name: checkLength(e.target.value),
                });
              }}
              error={LENGTH_ERROR}
              isValidated={validations.name}
            />
          </div>
        </div>
        <div class="mt-3">
          <dl>
            <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Imagen</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 md:flex">
                {user.imgURL ? (
                  <>
                    <div className="flex w-[25%] justify-start items-center">
                      <ImageModal url={user.imgURL} name={user.name} />
                      <button
                        onClick={() => deleteImage(user.imgURL, setUser, user)}
                        className="btn btn-sm btn-circle bg-red-500 hover:bg-red-700 text-white font-medium py-1 px-2 border-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </>
                ) : (
                  <p>
                    Selecciona un archivo:
                    <div className="cursor-pointer">
                      <p className="text-red-600 font-semibold">
                        *Formatos permitidos: jpeg, jpg, png
                      </p>

                      <InputFile id={"imgUser"} onChangeFile={setImageURL}>
                        <img
                          src={
                            user.imgURL ? user.imgURL : "/images/addImage.jpg"
                          }
                          className="cursor-pointer m-2 w-20"
                          alt="Imagen de usuario"
                        />
                      </InputFile>
                    </div>
                  </p>
                )}
              </dd>
            </div>

            {hasEmail && (
              <>
                <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">Correo</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <TextInput
                      value={user.id}
                      onChangeValue={(e) => {
                        setUser({ ...user, id: e.target.value });
                        setValidations({
                          ...validations,
                          email: checkEmail(e.target.value),
                        });
                      }}
                      error={EMAIL_ERROR}
                      isValidated={validations.email}
                    />
                  </dd>{" "}
                </div>
              </>
            )}

            {hasPassword && (
              <>
                <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">Contraseña</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <TextInput
                      type={"password"}
                      value={user.password}
                      onChangeValue={(e) => {
                        setUser({ ...user, password: e.target.value });
                        setValidations({
                          ...validations,
                          password: checkPass(e.target.value),
                        });
                      }}
                      error={PASSWORD_ERROR}
                      isValidated={validations.password}
                    />
                  </dd>
                </div>{" "}
              </>
            )}

            <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Rol</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <SelectRole
                  roleId={user.roleId}
                  onChangeRole={(roleId) => {
                    setUser({ ...user, roleId });
                  }}
                />
              </dd>
            </div>

            <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Sede</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {user.roleId && user.roleId !== "admin" ? (
                  <>
                    <SelectCellar
                      cellarId={user.cellarId}
                      onChangeCellar={(cellarId) =>
                        setUser({ ...user, cellarId })
                      }
                    />
                  </>
                ) : (
                  <p className="font-light italic">
                    Selecciona un rol (Admin sede o técnico)
                  </p>
                )}
              </dd>
            </div>

            {hasEnabledUser && (
              <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">Estado</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <SelectOption
                    disabled={user.disabled}
                    onChangeDisabled={(disabled) =>
                      setUser({ ...user, disabled })
                    }
                  />
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>
    </>
  );
};

export default UserForm;
