import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startInModal } from "../../../../../store/tab/thunks";
import { useMutation } from "@apollo/client";
import IconTextButton from "../../../../ui/buttons/IconTextButton";
import ModalTemplate from "../../../../ui/modals/ModalTemplate";
import successToast from "../../../../../utils/toast/successToast";
import { toast } from "react-toastify";
import { deleteTypename } from "../../../../../utils/deleteTypename";
import errorToast from "../../../../../utils/toast/errorToast";
import checkLoanFields from "../helpers/checkLoanFields";
import LoanForm from "../layout/LoanForm";
import LOAN_VALIDATIONS from "../helpers/validations";
import { UPDATELOANS } from "../../../../../graphql/mutations/loan/updateLoan";
import { LISTLOANS } from "../../../../../graphql/queries/loan/listLoans";

function EditLoan() {
  const dispatch = useDispatch();
  const { selectedElement } = useSelector((state) => state.tab);
  const [loan, setLoan] = useState(selectedElement.row);
  
  const statusTypes = ["EN PROCESO", "ENTREGADO"];

  const [validations, setValidations] = useState(LOAN_VALIDATIONS);

  const [updateLoanMutationFunction, { loading }] = useMutation(UPDATELOANS, {
    refetchQueries: [{ query: LISTLOANS }, "ListLoans"],
  });

  const onClickEdit = async () => {
    const { valid, toastText, toastInfo, newValidations } = checkLoanFields(
      loan,
      validations
    );

    if (!valid) {
      toast.error(toastText, toastInfo);
      setValidations({ ...newValidations });
    } else {
      editLoan();
    }
  };

  const editLoan = async () => {
    const { id, logs, cellar, tool, cellarTool, ...newpropsLoan } = deleteTypename(loan);


    console.log("newpropsLoan", newpropsLoan)

    await updateLoanMutationFunction({
      variables: {
        loan: newpropsLoan,
        updateLoanId: selectedElement.row.id,
      },
    })
      .then(() => {
        toast.success("Préstamo actualizado", successToast);
        dispatch(startInModal({ inModal: null }));
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.toString(), errorToast);
      });
  };

  return (
    <>
      <ModalTemplate
        title={"Editar préstamo"}
        onClickBack={() => {
          dispatch(startInModal({ inModal: null }));
        }}
      >
        <>
          <LoanForm
            loan={loan}
            setLoan={setLoan}
            name={"Préstamo"}
            statusTypes={statusTypes}
            validations={validations}
            setValidations={setValidations}
          />

          <div className="my-4 bg-white place-content-end flex p-4 rounded-lg shadow-sm">
            <IconTextButton
              icon="done"
              text="Guardar"
              onClickButton={onClickEdit}
              loading={loading}
            />
          </div>
        </>
      </ModalTemplate>
    </>
  );
}

export default EditLoan;
