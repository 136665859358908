import React from "react";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { LISTPRODUCTS } from "../../../../../graphql/queries/product/listProducts";

const SelectProduct = ({ productId, onChangeProduct, onChangeProductItem, cellarId }) => {
  const { company } = useSelector((state) => state.auth);

  const { data, loading, error } = useQuery(LISTPRODUCTS, {
    variables: { companyId: company.id, cellarId: cellarId, isApproved: true },
  });

  if (loading) return <p>Cargando...</p>;
  if (error) return console.log(error);

  return (
    <>
      {data && data.listProducts && (
        <select
          className="select w-4/5 "
          onChange={(e) => {
            if (data.listProducts && onChangeProductItem) {
              const item = data.listProducts.find(
                (_item) => _item.id === e.target.value
              );
              if (item) onChangeProductItem(item);
            } else if (data.listProducts && onChangeProduct) {
              onChangeProduct(e.target.value);
            }
          }}
          value={productId}
        >
          <option disabled selected>
            Selecciona un equipo
          </option>
          {data.listProducts.map((item) => (
            <option className="display-inline" key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      )}
    </>
  );
};

export default SelectProduct;
