import React from 'react'

const Icon = ({icon, tooltip, size}) => {
  return (
    <>
    {tooltip &&
        <div className="tooltip" data-tip={tooltip}> 
            <span className={`material-symbols-outlined  ${size ? + size : '' }`}>{icon}</span> 
        </div>
    }
    {!tooltip &&
        <span className={`material-symbols-outlined  ${size ? size : '' }`}>{icon}</span>
    }
    </>
  )
}

export default Icon;