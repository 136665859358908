import TableTitleApprove from "../../../../ui/table/titles/TableTitleApprove";
import TableTitleGoTo from "../../../../ui/table/titles/TableTitleGoTo";
import TableTitleImage from "../../../../ui/table/titles/TableTitleImage";

export const productTableColumns = [
  
    {
      Header: ' ',
      columns: [
        /* {
          Header: " ",
        }, */
        {
          Header: "Foto",
          accessor: "imgURL",
          Cell: TableTitleImage,
          disableSortBy: true,
          disableFilters: true,
        },
        {
          Header: 'Máquina',
          accessor: 'name',
        },
        {
          Header: 'Observaciones',
          accessor: 'description',
        },
        {
          Header: 'Factura',
          accessor: 'invoice',
        },
        {
          Header: 'Marca',
          accessor: 'mark',
        },
        {
          Header: 'Referencia',
          accessor: 'reference',
        },
        {
          Header: 'Serial',
          accessor: 'serial',
        },
        {
          Header: 'Precio',
          accessor: 'price',
        },
        {
          Header: 'Depreciación',
          accessor: 'depreciation',
        },
        {
          Header: 'Sedes',
          accessor: 'cellar.name',
        },
       
        {
          Header: 'Acción',
          accessor: 'moreInfo',
          Cell: TableTitleApprove,
          disableSortBy: true,
          disableFilters: true,
        },
      ],
    },
  ];