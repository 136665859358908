import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import Moment from "react-moment";
import moment from "moment/moment";

// Create styles
const styles = StyleSheet.create({
  page: {
    // flexDirection: 'row',
    //backgroundColor: '#E4E4E4',
    padding: 30,
    fontSize: 12,
    //   alignItems: 'center',
    //  justifyContent: 'center',
    // display: 'flex'
  },
  section: {
    display: "flex",
    flexDirection: "row",
  },
  headerContent: {
    paddingLeft: 20,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  main: {
    flex: 1,
  },
  toolName: {
    marginTop: 10,
    marginBottom: 10,
    alignSelf: "center",
    textAlign: "center",
  },
  nameTitle: {
    fontWeight: "bold",
  },
  toolInfoTable: {
    flexDirection: "row",
  },
  infoContent: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  serviceInfo: {
    display: "flex",
    flexDirection: "row",
  },
});

// Create Document Component
export const ExportToolPDF = ({ tool }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.headerContent}>
        <Image
          src={"/images/logofw.png"}
          style={{
            width: "110px",
          }}
        />
        <View style={styles.section}>
          {/*<img style={{
            width: '60px',
            height: '60px',
            borderRadius: '90',
          }} /> */}

          <View style={styles.headerContent}>
            <Text>Cliente: CARIBE MOTOR DE MEDELLÍN S.A.S.</Text>
            <Text>Sede: {tool.cellar.name}</Text>
          </View>
        </View>
        <View style={styles.toolName}>
          <Text style={styles.nameTitle}>HERRAMIENTA: {tool.name}</Text>
        </View>
        <View style={styles.toolInfoTable}>
          <View style={styles.infoContent}>
            <Text>Equipo</Text>
            <Text>{tool.mark}</Text>
          </View>
          <View style={styles.infoContent}>
            <Text>Serial</Text>
            <Text>{tool.serial}</Text>
          </View>
          <View style={styles.infoContent}>
            <Text>Factura</Text>
            <Text>{tool.invoice}</Text>
          </View>
        </View>

        {/* <View style={styles.toolInfoTable}>
          <View style={styles.infoContent}>
            <Text>Referencia</Text>
            <Text>{tool.reference}</Text>
          </View>
          <View style={styles.infoContent}>
            <Text>Precio</Text>
            <Text>{tool.price}</Text>
          </View>
          <View style={styles.infoContent}>
            <Text>Stock</Text>
            <Text>{tool.stock}</Text>
          </View>
        </View> */}
        {/* <View style={styles.toolName}>
          <Text style={styles.nameTitle}>HISTORIAL DE SERVICIOS</Text>
        </View>
        <View style={styles.serviceInfo}>
          <View style={{ width: "25%" }}>
            <Text>N° servicio</Text>
          </View>
          <View style={{ width: "25%" }}>
            <Text>Estado</Text>
          </View>
          <View style={{ width: "25%" }}>
            <Text>Fecha de solicitud</Text>
          </View>
          <View style={{ width: "25%" }}>
            <Text>Descripción</Text>
          </View>
        </View> */}

        {/* {tool.productServices.map((item) => (
          <View style={styles.serviceInfo}>
            <View style={{ width: "25%" }}>
              <Text>{item.id}</Text>
            </View>
            <View style={{ width: "25%" }}>
              <Text>{item.statusId}</Text>
            </View>
            <View style={{ width: "25%" }}>
              <Text>
                {moment(item.creationDate, "YYYY-MM-DD-hh-mm Z").format("Y-D-M hh:mm")}
              </Text>
            </View>
            <View style={{ width: "25%" }}>
              <Text>{item.description}</Text>
            </View>
          </View>
        ))} */}
      </View>
    </Page>
  </Document>
);
