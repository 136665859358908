import React from "react";

const Loading = () => {
  return (
    <>
      <div className="flex justify-center items-center h-screen">
        <div
          className="spinner-border animate-spin-slow inline-block w-28 h-28 border-4"
          role="status"
        ></div>
      </div>
    </>
  );
};

export default Loading;
