import React from "react";
import checkLength from "../../../../../utils/checkLength";
import {
  DATE_RANGE_ERROR,
  EMAIL_ERROR,
  LENGTH_ERROR,
} from "../../../../admin/pages/users/helpers/inputErrors";
import TextInput from "../../../../ui/Inputs/TextInput";
import { ActionHiddenInputGuard } from "../../../../../guards/ActionHiddenInputGuard.guard";
import { useSelector } from "react-redux";
import checkEmail from "../../../../../utils/checkEmail";
import { useEffect } from "react";
import SelectCellar from "../../shared/Inputs/SelectCellar";
import SelectProduct from "../../shared/Inputs/SelectProduct";
import SelectTool from "../../shared/Inputs/SelectTool";

const LoanForm = ({
  loan,
  setLoan,
  statusTypes,
  validations,
  setValidations,
}) => {
  const { inModal } = useSelector((state) => state.tab);

  /* console.log("loan", loan);
   */
  //change state when change finishDate
  useEffect(() => {
    if (loan.creationDate && loan.finishDate) {
      const isValid = loan.creationDate < loan.finishDate;

      setValidations({
        ...validations,
        finishDate: isValid,
      });
      console.log("isValid", isValid);
    }
  }, [loan.creationDate, loan.finishDate]);

  const onClickAddEmailInput = () => {
    console.log("add email");
    setLoan({ ...loan, emails: [...loan.emails, ""] });
  };

  //Add email inputs
  const onChangeEmail = (value, iEmail) => {
    setLoan({
      ...loan,
      emails:
        loan.emails && loan.emails.map((s, i) => (i === iEmail ? value : s)),
    });
  };

  const onDeleteEmail = (index) => {
    setLoan({
      ...loan,
      emails: loan.emails.filter((s, i) => i !== index),
    });
  };

  const onChangeCreationDate = (value) => {
    setLoan({
      ...loan,
      creationDate: value,
    });
  };

  const onChangeFinishDate = (value) => {
    const isValid = loan.creationDate <= value;

    setLoan({
      ...loan,
      finishDate: value,
    });
  };

  const onChangeValueType = (type) => {
    setLoan({ ...loan, status: type });
  };

  const onChangeValueCellar = (cellarId) => {
    console.log("onchangevaluecellar");
    setLoan({ ...loan, cellarId: cellarId });
  };

  const onChangeValueCellarToolId = (cellarId) => {
    console.log("onChangeValueCellarToolId");
    setLoan({ ...loan, cellarToolId: cellarId });
  };

  const onChangeValueTool = (toolId) => {
    console.log("onchangevalueproduct");
    setLoan({ ...loan, toolId: toolId });
  };

  const onChangeValueToolItem = (item)=> {
    console.log("onChangeValueToolItem")
    setLoan({ ...loan, toolId: item.id, toolName: item.name });}
  

  return (
    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
      {/* <div class="px-4 py-5 sm:px-6 bg-slate-200">
        <div className="md:flex">
          <TextInput
            title={`Nombre de herramienta`}
            value={loan.name}
            onChangeValue={(e) => {
              setLoan({ ...loan, name: e.target.value });
              setValidations({
                ...validations,
                name: checkLength(e.target.value),
              });
            }}
            error={LENGTH_ERROR}
            isValidated={validations.name}
          />
        </div>
      </div> */}

      <div class="mt-3">
        <dl>
          <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Descripción</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <TextInput
                value={loan.description}
                onChangeValue={(e) => {
                  setLoan({ ...loan, description: e.target.value });
                  setValidations({
                    ...validations,
                    description: checkLength(e.target.value),
                  });
                }}
                error={LENGTH_ERROR}
                isValidated={validations.description}
              />
            </dd>
          </div>

          <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              Nombre de prestamista
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <TextInput
                value={loan.userName}
                onChangeValue={(e) => {
                  setLoan({ ...loan, userName: e.target.value });
                  setValidations({
                    ...validations,
                    userName: checkLength(e.target.value),
                  });
                }}
                error={LENGTH_ERROR}
                isValidated={validations.userName}
              />
            </dd>
          </div>

          <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              Nombre de prestatario
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <TextInput
                value={loan.userNameCreatedBy}
                onChangeValue={(e) => {
                  setLoan({ ...loan, userNameCreatedBy: e.target.value });
                  setValidations({
                    ...validations,
                    userNameCreatedBy: checkLength(e.target.value),
                  });
                }}
                error={LENGTH_ERROR}
                isValidated={validations.userNameCreatedBy}
              />
            </dd>
          </div>

          <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Correos</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <ActionHiddenInputGuard
                typeModal={inModal}
                childrenEdit={
                  loan.emails ? (
                    loan.emails.map((email) => (
                      <div className="badge bg-slate-500 border-none rounded text-slate-50 my-1 flex">
                        <p>{email}</p>
                      </div>
                    ))
                  ) : (
                    <p>No hay correos para mostrar</p>
                  )
                }
                childrenAdd={
                  <>
                    {loan.emails ? (
                      <>
                        {loan.emails.map((email, iEmail) => (
                          <>
                            <div className="my-2">
                              <TextInput
                                value={email}
                                onChangeValue={(e) => {
                                  onChangeEmail(e.target.value, iEmail);
                                  setValidations({
                                    ...validations,
                                    email: checkEmail(e.target.value),
                                  });
                                }}
                                error={EMAIL_ERROR}
                                isValidated={validations.email}
                              />
                            </div>
                            <button
                              className="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded flex align-middle"
                              type="submit"
                              onClick={() => onDeleteEmail(iEmail)}
                            >
                              <span class="material-symbols-outlined">
                                delete
                              </span>
                              Eliminar
                            </button>
                          </>
                        ))}

                        {loan.emails.length < 5 && (
                          <button
                            className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded my-1 flex align-middle"
                            type="submit"
                            onClick={() => onClickAddEmailInput()}
                          >
                            <span class="material-symbols-outlined">add</span>
                            Agregar correo
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        <button
                          className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded my-1 flex align-middle"
                          type="submit"
                          onClick={() => onClickAddEmailInput()}
                        >
                          <span class="material-symbols-outlined">add</span>
                          Agregar correo
                        </button>
                      </>
                    )}
                  </>
                }
              />
            </dd>
          </div>

          <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Fecha del préstamo
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <ActionHiddenInputGuard
                typeModal={inModal}
                childrenEdit={<p>{loan.creationDate}</p>}
                childrenAdd={
                  <TextInput
                    type="date"
                    value={loan.creationDate}
                    onChangeValue={(e) => {
                      onChangeCreationDate(e.target.value);
                      setValidations({
                        ...validations,
                        creationDate: checkLength(e.target.value),
                      });
                    }}
                    error={LENGTH_ERROR}
                    isValidated={validations.creationDate}
                  />
                }
              />
            </dd>
          </div>

          <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Fecha de devolución
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <ActionHiddenInputGuard
                typeModal={inModal}
                childrenEdit={<p>{loan.finishDate}</p>}
                childrenAdd={
                  <TextInput
                    type="date"
                    value={loan.finishDate}
                    onChangeValue={(e) => {
                      onChangeFinishDate(e.target.value);
                    }}
                    error={DATE_RANGE_ERROR}
                    isValidated={validations.finishDate}
                  />
                }
              />
            </dd>
          </div>

          <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Sede de la herramienta
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <ActionHiddenInputGuard
                typeModal={inModal}
                childrenEdit={
                  <p>{loan.cellarTool?.name ? loan.cellarTool?.name : ""}</p>
                }
                childrenAdd={
                  <SelectCellar
                    cellarId={loan.cellarToolId}
                    onChangeCellar={(cellarToolId) =>
                      onChangeValueCellarToolId(cellarToolId)
                    }
                  />
                }
              />
            </dd>
          </div>

          <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Herramienta</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {loan.cellarToolId != null ? (
                <ActionHiddenInputGuard
                  typeModal={inModal}
                  childrenEdit={<p>{loan.tool ? loan.tool.name : ""}</p>}
                  childrenAdd={
                    <SelectTool
                      toolId={loan.toolId}
                      /* onChangeTool={(toolId) =>
                        onChangeValueTool(toolId)
                      } */
                      onChangeToolItem={(item) =>
                        onChangeValueToolItem(item)
                      }
                      cellarId={loan.cellarToolId}
                    />
                  }
                />
              ) : (
                <p className="font-light italic">Selecciona una sede.</p>
              )}
            </dd>
          </div>

          <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Sede destino</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <ActionHiddenInputGuard
                typeModal={inModal}
                childrenEdit={<p>{loan.cellar ? loan.cellar.name : ""}</p>}
                childrenAdd={
                  <SelectCellar
                    cellarId={loan.cellarId}
                    onChangeCellar={(cellarId) => onChangeValueCellar(cellarId)}
                  />
                }
              />
            </dd>
          </div>

          <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Estado</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <ActionHiddenInputGuard
                typeModal={inModal}
                childrenEdit={
                  <>
                    {statusTypes &&
                      statusTypes.map((type) => (
                        <>
                          <div
                            onChange={(e) => {
                              onChangeValueType(type);
                              setValidations({
                                ...validations,
                                status: checkLength(e.target.value),
                              });
                            }}
                          >
                            <input
                              checked={loan.status === type}
                              type="radio"
                              key={`${type}`}
                              id={`${type}`}
                              name={`${type}`}
                              error={LENGTH_ERROR}
                              isValidated={validations.status}
                            />
                            <label htmlFor={`${type}`}>{type}</label>
                          </div>
                        </>
                      ))}
                  </>
                }
                childrenAdd={
                  <>
                    {statusTypes &&
                      statusTypes.map((type) => (
                        <>
                          <div
                            onChange={(e) => {
                              onChangeValueType(type);
                              setValidations({
                                ...validations,
                                status: checkLength(e.target.value),
                              });
                            }}
                          >
                            <input
                              checked={loan.status === type}
                              type="radio"
                              key={`${type}`}
                              id={`${type}`}
                              name={`${type}`}
                              error={LENGTH_ERROR}
                              isValidated={validations.status}
                            />
                            <label htmlFor={`${type}`}>{type}</label>
                          </div>
                        </>
                      ))}
                  </>
                }
              />
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default LoanForm;
