import React from "react";
import IconButton from "../buttons/IconButton";

const ModalTemplate = ({ title, children, onClickBack }) => {
  return (
    <div className="absolute w-[-webkit-fill-available] h-[92vh] bg-slate-50 z-20 overflow-y-scroll shadow-modal m-16 rounded-lg">
      <div className="flex w-full items-center text-2xl px-8 py-4 bg-white shadow-sm">
        <div onClick={onClickBack} className="">
          <IconButton icon={"arrow_back"} ghost />
        </div>

        <div className="p-4 px-10 self-center items-center">
          <p className="  font-bold ">{title}</p>
        </div>
      </div>

      <div id="modal-container">
        <div className=" p-6">{children}</div>
      </div>
    </div>
  );
};

export default ModalTemplate;

// w-[-webkit-fill-available]
