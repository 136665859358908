import React from "react";
import checkLength from "../../../../../utils/checkLength";
import { deleteImage } from "../../../../../utils/deleteImage";
import { resizeFile } from "../../../../../utils/resizeFile";
import {
  LENGTH_ERROR,
  NUMBER_ERROR,
} from "../../../../admin/pages/users/helpers/inputErrors";
import InputDocument from "../../../../ui/buttons/InputDocument";
import InputFile from "../../../../ui/buttons/InputFile";
import { PDFButton } from "../../../../ui/buttons/PDFButton";
import TextInput from "../../../../ui/Inputs/TextInput";
import ImageModal from "../../../../ui/modals/ImageModal";
import SelectCellar from "../../shared/Inputs/SelectCellar";
import checkTypeNumber from "../../../../../utils/checkTypeNumber";

const ProductForm = ({
  product,
  setProduct,
  name,
  validations,
  setValidations,
}) => {
  const setImageURL = async (file) => {
    /* console.log("file", file); */
    if (file && file.size) {
      const newFile = await resizeFile(file, { maxWidth: 512, maxHeight: 512 });
      var _file = URL.createObjectURL(newFile);

      setProduct({
        ...product,
        imgURL: _file,
        imgBlob: newFile,
        imageType: file.type,
      });
    }
  };

  const setDocumentURL = ({ filelist }) => {
    /* console.log("setDocumentURL", filelist); */
    let arrDocuments = [];
    let arrTypes = [];
    let arrDocumentsBlob = [];

    //Si dentro del filelist hay más de 1 elemento
    if (filelist.length > 0) {
      //Itera todo el filelist[]
      for (var i = 0, file; (file = filelist[i]); i++) {
        //Si existe el archivo y tiene un size
        if (file && file.size) {
          //Extrae el blob y url local
          var newFile = new Blob([file], { type: file.type });
          var _file = URL.createObjectURL(newFile);

          //Push del objeto con su name y url hacia el array de todos los documentos
          arrDocuments.push({ name: file.name, url: _file });
          //Push del tipo hacia el array de todos los tipos
          arrTypes.push(file.type);
          //Push del objeto con su blob hacia el array de todos los blob
          arrDocumentsBlob.push(newFile);
        }
      }

      setProduct({
        ...product,
        documentsURL: arrDocuments,
        documentsBlob: arrDocumentsBlob,
        documentsType: arrTypes,
      });
    }
  };

  const onClickDeleteDocuments = (index) => {
    if (index !== null) {
      setProduct({
        ...product,
        documentsURL:
          product.documentsURL &&
          product.documentsURL.filter((_, i) => i !== index),
        documentsBlob:
          product.documentsBlob &&
          product.documentsBlob.filter((_, i) => i !== index),
        documentsType:
          product.documentsType &&
          product.documentsType.filter((_, i) => i !== index),
      });
    }
  };

  return (
    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6 bg-slate-200">
        <div className="md:flex">
          <TextInput
            title={`Nombre de ${name}`}
            value={product.name}
            onChangeValue={(e) => {
              setProduct({ ...product, name: e.target.value });
              setValidations({
                ...validations,
                name: checkLength(e.target.value),
              });
            }}
            error={LENGTH_ERROR}
            isValidated={validations.name}
          />
        </div>
      </div>

      <div class="mt-3">
        <dl>
          <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Multimedia</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 md:flex">
              <input
                value={product.multimediaURL}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    multimediaURL: e.target.value,
                  })
                }
                type="text"
                placeholder="Link"
                className="input input-bordered w-full max-w-xs"
              />
            </dd>
          </div>

          <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Imagen</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 md:flex">
              {product.imgURL ? (
                <>
                  <div className="flex w-[25%] justify-start items-center">
                    <ImageModal url={product.imgURL} name={product.name} />
                    <button
                      onClick={() =>
                        deleteImage(product.imgURL, setProduct, product)
                      }
                      className="btn btn-sm btn-circle bg-red-500 hover:bg-red-700 text-white font-medium py-1 px-2 border-none"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </>
              ) : (
                <p>
                  Selecciona un archivo:
                  <div className="cursor-pointer">
                    <InputFile id={"imgProduct"} onChangeFile={setImageURL}>
                      <img
                        src={
                          product.imgURL
                            ? product.imgURL
                            : "/images/addImage.jpg"
                        }
                        className="cursor-pointer m-2 w-20"
                        alt="Imagen de la máquina"
                      />
                    </InputFile>
                  </div>
                </p>
              )}
            </dd>
          </div>

          <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Documentos</dt>
            <div className="mt-1 flex flex-col">
              {product.documentsURL.length > 0 ? (
                product.documentsURL.map((item, index) => (
                  <div className="flex justify-center items-center">
                    <PDFButton key={index} item={item} />
                    <button
                      id={index}
                      onClick={() => onClickDeleteDocuments(index)}
                      className="btn btn-sm btn-circle bg-red-500 hover:bg-red-700 text-white font-medium py-1 px-2 mx-2 border-none"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                ))
              ) : (
                <p>
                  Selecciona uno o varios archivos:
                  <p className="text-red-600 font-semibold">
                    *Formatos permitidos: pdf, excel, csv, word, powerpoint,
                    texto plano
                  </p>
                  <InputDocument
                    id={"documentProduct"}
                    onChangeFile={setDocumentURL}
                  >
                    <img
                      src={"/images/addImage.jpg"}
                      className="cursor-pointer m-2 w-20 justify-center"
                      alt="Documentos referentes a la máquina"
                    />
                  </InputDocument>
                </p>
              )}
            </div>
          </div>

          <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Descripción</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <TextInput
                value={product.description}
                onChangeValue={(e) => {
                  setProduct({ ...product, description: e.target.value });
                }}
              />
            </dd>
          </div>

          <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Factura</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <TextInput
                value={product.invoice}
                onChangeValue={(e) => {
                  setProduct({ ...product, invoice: e.target.value });
                }}
              />
            </dd>
          </div>

          <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Marca</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <TextInput
                value={product.mark}
                onChangeValue={(e) => {
                  setProduct({ ...product, mark: e.target.value });
                }}
              />
            </dd>
          </div>

          <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Referencia</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <TextInput
                value={product.reference}
                onChangeValue={(e) => {
                  setProduct({ ...product, reference: e.target.value });
                }}
              />
            </dd>
          </div>

          <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Serial</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <TextInput
                value={product.serial}
                onChangeValue={(e) => {
                  setProduct({ ...product, serial: e.target.value });
                }}
              />
            </dd>
          </div>
          <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Precio</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <TextInput
                type="number"
                value={product.price}
                onChangeValue={(e) => {
                  setProduct({ ...product, price: e.target.value });
                  setValidations({
                    ...validations,
                    price: checkTypeNumber(e.target.value),
                  });
                }}
                onSelectFocus={(e) => {
                  e.target.select();
                }}
                error={NUMBER_ERROR}
                isValidated={validations.price}
              />
            </dd>
          </div>
          <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Depreciación</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <TextInput
                type="number"
                value={product.depreciation}
                onChangeValue={(e) => {
                  setProduct({ ...product, depreciation: e.target.value });
                  setValidations({
                    ...validations,
                    depreciation: checkTypeNumber(e.target.value),
                  });
                }}
                onSelectFocus={(e) => {
                  e.target.select();
                }}
                error={NUMBER_ERROR}
                isValidated={validations.depreciation}
              />
            </dd>
          </div>

          <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Stock</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <TextInput
                type="number"
                value={product.stock}
                onChangeValue={(e) => {
                  setProduct({ ...product, stock: e.target.value });
                  setValidations({
                    ...validations,
                    stock: checkTypeNumber(e.target.value),
                  });
                }}
                onSelectFocus={(e) => {
                  e.target.select();
                }}
                error={NUMBER_ERROR}
                isValidated={validations.stock}
              />
            </dd>
          </div>

          <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Sede</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <SelectCellar
                cellarId={product.cellarId}
                onChangeCellar={(cellarId) =>
                  setProduct({ ...product, cellarId })
                }
              />
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default ProductForm;
