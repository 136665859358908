import React, { useState, useMemo, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { startComponent, startDataCRUD } from "../../../../../store/tab/thunks";
import { LISTPRODUCTS } from "../../../../../graphql/queries/product/listProducts";
import TabLayout from "../../shared/TabLayout";
import Loading from "../../../../ui/loading/Loading";
import { productTableColumns } from "./productTableColumns";
import AddProduct from "../pages/AddProduct";
import EditProduct from "../pages/EditProduct";
import ViewProduct from "../pages/ViewProduct";

const Product = () => {
  const dispatch = useDispatch();
  const { company, cellarId } = useSelector((state) => state.auth);
  const { dataResponse } = useSelector((state) => state.tab);

  const { data, loading } = useQuery(LISTPRODUCTS, {
    variables: { companyId: company.id, cellarId: cellarId, isApproved: true },
    onCompleted: (data) => {
      dispatch(
        startDataCRUD({
          dataResponse: data.listProducts,
        })
      );
    },
  });

  console.log("dataproduct", data);

  const columns = useMemo(() => productTableColumns, []);

  const range = (len) => {
    const arr = [];
    for (let i = 0; i < len; i++) {
      arr.push(i);
    }
    return arr;
  };

  const newProduct = () => {
    return {
      name: "example",
    };
  };

  /* const makeData = (...lens) => {
    const makeDataLevel = (depth = 0) => {
      const len = lens[depth];
      return range(len).map((d) => {
        return {
          ...newProduct(),
          subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
        };
      });
    };
    return makeDataLevel();
  };
  const serverData = makeData(1000); 

  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);*/

  // const fetchData = useCallback(
  //   ({ pageSize, pageIndex, sortBy, filters, groupBy }) => {
  //     const fetchId = ++fetchIdRef.current;
  //     //setLoading(true);

  //     // We'll even set a delay to simulate a server here
  //     setTimeout(() => {
  //       // Only update the data if this is the latest fetch
  //       if (fetchId === fetchIdRef.current) {
  //         /* console.log("pageIndex", pageIndex); */
  //         const startRow = pageSize * pageIndex;
  //         const endRow = startRow + pageSize;
  //         //data.slice(startRow, endRow);

  //         // Your server could send back total page count.
  //         // For now we'll just fake it, too
  //         setPageCount(Math.ceil(serverData.length / pageSize));
  //       }
  //     }, 1000);
  //   },
  //   []
  // );

  dispatch(startComponent({ component: "Product" }));

  if (loading) return <Loading />;

  /* if (error) return console.log(error); */

  return (
    <>
      <TabLayout
        titleComponent={"Maquinaria"}
        AddElement={AddProduct}
        EditElement={EditProduct}
        ViewElement={ViewProduct}
        nameElement={"maquinaria"}
        data={data}
        dataQuery={dataResponse}
        columns={columns}
        // fetchData={fetchData}
        loading={loading}
        // pageCount={pageCount}
        hasDownloadBtn={true}
      />
    </>
  );
};

export default Product;
