import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PrivateRoutes } from "../../../router/routes";
import { themeStyle } from "../../../styles";

export const ScreenForm = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("dataUser")) {
      navigate(PrivateRoutes.HOME);
    }
  }, []);

  return (
    <div className="flex w-full h-screen ">
      <div
        className={` w-full bg-light flex items-center justify-center lg:w-3/5`}
      >
        <div className="px-10 py-20  ">
          <div className="flex justify-center justify-items-center">
            <img className="w-52" src={themeStyle.logo} alt="Logo de iCON" />
          </div>
          <p className="font-medium text-base text-md text-gray-800 mt-4">
            Por favor digita tu información.
          </p>
          {children}
        </div>
      </div>

      <div
        className="hidden md:block md:w-full md:bg-cover md:bg-center"
        style={{
          backgroundImage: `url(${themeStyle.loginBackground})`,
        }}
      />
    </div>
  );
};
