
const styles = {
    renault: {
        primary: '#fed500',
        dark: 'black',
        light: 'white',
        logo: '/images/logos/renault.png',
        logoLight: '/images/logos/renault_light.png',
        loginBackground: '/images/background/iconservicios.png',
        textPrimary: 'black',
        textDark: 'black',
        textLight: 'white'
    },
    metrokia: {
        primary: '#E0E0E0',
        dark: 'white',
        light: 'white',
        logo: 'https://thingsmanager.s3.amazonaws.com/metrokia/assets/navbarLogo.png',
        logoLight: 'https://thingsmanager.s3.amazonaws.com/metrokia/assets/navbarLogo.png',
        loginBackground: '/images/background/inventory.jpg',
        textPrimary: 'black',
        textDark: 'black',
        textLight: 'black'
    }
}

//const themeStyle = styles['kia'];
const themeStyle =  styles[process.env.REACT_APP_APP]

module.exports = {
    themeStyle: themeStyle
}