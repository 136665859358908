import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionUpdateGuard } from "../../../../../guards/actions.guard";
import { startInModal } from "../../../../../store/tab/thunks";
import IconTextButton from "../../../../ui/buttons/IconTextButton";
import ModalTemplate from "../../../../ui/modals/ModalTemplate";
import ZoneDetail from "../layout/ZoneDetail";

const ViewZone = () => {
  const dispatch = useDispatch();
  const { selectedElement } = useSelector((state) => state.tab);
  const zone = selectedElement.row;

  return (
    <>
      <ModalTemplate
        title={"Ver detalles"}
        onClickBack={() => {
          dispatch(startInModal({ inModal: null }));
        }}
      >
        <>
          <ZoneDetail zone={zone} name={"sede"} />
          <ActionUpdateGuard
            children={
              <div className="my-4 bg-white place-content-end flex p-4 rounded-lg shadow-sm">
                <IconTextButton
                  icon="edit"
                  text="Editar"
                  onClickButton={() => {
                    dispatch(startInModal({ inModal: "edit" }));
                  }}
                />
              </div>
            }
          />
        </>
      </ModalTemplate>
    </>
  );
};

export default ViewZone;
