import React, { useState } from 'react'

const TableTitleImage = ({
  cell: { value }
}) => {

  const [imgValue, setImgValue] = useState(value);

  return (

    <div className="avatar">
        <div className="mask mask-squircle w-12 h-12">
        {
        imgValue != null  && 
        <img src={imgValue} alt="imágen"
        onError={()=> {/*  console.log("err"); */ setImgValue('images/addImage.jpg')}}
        
        />
        }
        {
        imgValue == null && 
        <img src='/images/addImage.jpg' alt="img"/>
        }
        </div>
    </div>
    

  )
}

export default TableTitleImage