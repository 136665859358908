import React from "react";
import Moment from "react-moment";
import ColoredLine from "../../../../ui/coloredLine/ColoredLine";
import ImageModal from "../../../../ui/modals/ImageModal";
import StatusField from "../../../../ui/statusUi/StatusField";

const RequestDetail = ({ order, services, name }) => {
  return (
    <>
      <div class="overflow-hidden bg-white shadow sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6 bg-slate-200">
          <div className="md:flex">
            <div className="w-full h-fit">
              <dt class="text-sm font-medium text-gray-500">N° de {name}</dt>
              {order.id}
            </div>

            <div className="w-full h-fit">
              <dt class="text-sm font-medium text-gray-500">Asunto</dt>
              {order.name}
            </div>

            <div className="w-full h-fit">
              <dt class="text-sm font-medium text-gray-500">
                Fecha de apertura
              </dt>

              <Moment locale="co" format="YYYY-MM-DD hh:mm">
                {new Date(order.creationDate)}
              </Moment>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <dl>
            {services.map((service, indexService) => (
              <>
                <div
                  className={`collapse ${
                    indexService === 0 ? "collapse-open" : ""
                  } collapse-arrow`}
                >
                  <input type="checkbox" className="peer" />
                  <div className="collapse-title bg-slate-200  peer-checked:text-secondary-content">
                    <div class="">
                      <h3 class="text-md font-medium leading-6 text-slate-700">
                        <div>Detalles del servicio</div>
                      </h3>
                    </div>
                  </div>
                  <div className="collapse-content text-primary-content peer-checked:text-secondary-content">
                    <div className="flex w-full">
                      <div className="w-4/6">
                        <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">
                            Descripción
                          </dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {service.description}
                          </dd>
                        </div>

                        <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">
                            Sede
                          </dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {service.cellar.name}
                          </dd>
                        </div>

                        <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">
                            Equipo
                          </dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {service.product.name}
                          </dd>
                        </div>


                        <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">Correos</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {service.emails ? (
                        service.emails.map((email) => (
                          <div className="badge bg-slate-500 border-none rounded text-slate-50 my-1 flex">
                            <p>{email}</p>
                          </div>
                        ))
                      ) : (
                        <p className="font-light italic">No se registraron correos.</p>
                      )}
                    </dd>
                  </div>


                        <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">
                            Imágenes
                          </dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 md:flex">
                            {service.imgsURL.length > 0 ? (
                              service.imgsURL.map((i, index) => (
                                <ImageModal
                                  url={i.url}
                                  name={i.name}
                                  indexService={indexService}
                                  index={index}
                                />
                              ))
                            ) : (
                              <p className="font-light italic">
                                No hay imágenes disponibles.
                              </p>
                            )}
                          </dd>
                        </div>

                        <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">
                            Tipo de servicio
                          </dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {service.serviceTypeId}
                          </dd>
                        </div>

                        <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">
                            Fecha del servicio
                          </dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {service.date}
                          </dd>
                        </div>

                        <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">
                            Estado
                          </dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <StatusField
                              statusId={service.statusId}
                              children={<p>{service.statusId}</p>}
                            />
                          </dd>
                        </div>
                      </div>
                      <div className="w-2/6 h-60 overflow-y-scroll">
                        <p className="font-bold text-slate-600 p-4 pb-0">Historial de cambios</p>
                        {service.logs ? (
                          service.logs &&
                          Object.entries(JSON.parse(service.logs))
                            .sort(
                              (a, b) =>
                                new Date(a[1].date) - new Date(b[1].date)
                            )
                            .map(([key, item]) => (
                              <div className="p-4 bg-white text-sm">
                                <StatusField
                                  statusId={key}
                                  children={<p>{key}</p>}
                                />
                                <div className="flex">
                                  <p className="font-semibold mr-2">
                                    Creado por:
                                  </p>
                                  {item.createdBy}
                                </div>
                                <div className="flex">
                                  <p className="font-semibold mr-2">Fecha:</p>
                                  <Moment locale="co" format="YYYY-MM-DD HH:mm">
                                    {new Date(item.date)}
                                  </Moment>
                                </div>
                              </div>
                            ))
                        ) : (
                          <p>No hay logs para mostrar.</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <ColoredLine color={"gray"} />
              </>
            ))}
          </dl>
        </div>
      </div>
    </>
  );
};
export default RequestDetail;
