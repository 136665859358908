import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { REGISTER } from "../../../../../graphql/mutations/auth/register";
import { LISTUSERS } from "../../../../../graphql/queries/user/listUsers";
import {
  startInModal,
} from "../../../../../store/tab/thunks";
import errorToast from "../../../../../utils/toast/errorToast";
import successToast from "../../../../../utils/toast/successToast";
import uploadFile from "../../../../../utils/uploadFile";
import IconTextButton from "../../../../ui/buttons/IconTextButton";
import ModalTemplate from "../../../../ui/modals/ModalTemplate";
import checkUserFields from "../helpers/checkUserFields";
import USER_VALIDATIONS from "../helpers/userValidations";
import UserForm from "./UserForm";

function AddUser() {
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.auth);
  const { inModal } = useSelector((state) => state.tab);

  const [user, setUser] = useState({
    name: "",
    id: "",
    password: "",
    imgURL: null,
    imageType: "",
    imgBlob: null,
    roleId: null,
    cellarId: null,
    companyId: company.id,
  });

  const [validations, setValidations] = useState(USER_VALIDATIONS);

  const [register, { loading }] = useMutation(REGISTER, {
    refetchQueries: [{ query: LISTUSERS }, "ListUsers"],
  });

  const onClickAdd = async () => {
    const { valid, toastText, toastInfo, newValidations } = checkUserFields(
      user,
      validations
    );

    var validSelect = true;

    if (user.roleId == null) {
      validSelect = false;
    }
    if (user.cellarId == null && user.roleId && user.roleId !== "admin") {
      validSelect = false;
    }

    if (!valid) {
      toast.error(toastText, toastInfo);
      setValidations({ ...newValidations });
    } else {
      if (!validSelect) {
        const resp = {
          toastText: "ERROR: Verifica la selección de sede o rol.",
          toastInfo: {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        };
        toast.error(resp.toastText, resp.toastInfo);
      } else {
        if (user.imgBlob != null) {
          const url = await uploadFile(
            `products/${user.id}`,
            user.imgBlob,
            user.imageType
          );
          if (url && url.Location) {
            user.imgURL = url.Location;
            setUser({ ...user, imgURL: url.Location });
          }
        }
        addUser();
      }
    }
  };

  const addUser = () => {
    const { imgBlob, imageType, password, ...propsUser } = user;

    register({
      variables: {
        user: propsUser,
        password,
      },
    })
      .then(() => {
        toast.success("Usuario agregado", successToast);
        dispatch(startInModal({ inModal: null }));
      })
      .catch((error) => {
        toast.error(error.toString(), errorToast);
      });
  };

  return (
    <>
      <ModalTemplate
        title={"Añadir Usuario"}
        onClickBack={() => {
          dispatch(startInModal({ inModal: null }));
        }}
      >
        <>
          <UserForm
            user={user}
            setUser={setUser}
            validations={validations}
            setValidations={setValidations}
            hasPassword={true}
            hasEmail={true}
            hasEnabledUser={false}
          />

          <div className="my-4 bg-white place-content-end flex p-4 rounded-lg shadow-sm">
            <IconTextButton
              icon="done"
              text="Guardar"
              onClickButton={onClickAdd}
              loading={loading}
            />
          </div>
        </>
      </ModalTemplate>
    </>
  );
}

export default AddUser;
