import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import IconTextButton from "../../../../ui/buttons/IconTextButton";
import ModalTemplate from "../../../../ui/modals/ModalTemplate";
import successToast from "../../../../../utils/toast/successToast";
import errorToast from "../../../../../utils/toast/errorToast";
import { startInModal } from "../../../../../store/tab/thunks";
import { toast } from "react-toastify";
import { LISTPRODUCTS } from "../../../../../graphql/queries/product/listProducts";
import { CREATEPRODUCTS } from "../../../../../graphql/mutations/products/createProduct";
import uploadFile from "../../../../../utils/uploadFile";
import PRODUCT_VALIDATIONS from "../helpers/validations";
import checkProductFields from "../helpers/checkProductFields";
import ProductForm from "../layout/ProductForm";

function AddProduct() {
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.auth);

  const [product, setProduct] = useState({
    name: "",
    description: "",
    reference: "",
    companyId: company.id,
    cellarId: null,
    isApproved: false,
    multimediaURL: [""],
    documentsURL: [],
    documentsType: [],
    documentsBlob: [],
    imgURL: null,
    imageType: "",
    imgBlob: null,
    invoice: "",
    mark: "",
    serial: "",
    price: 0,
    depreciation: 0,
    stock: 0
  });

  const [validations, setValidations] = useState(PRODUCT_VALIDATIONS);

  const [createProductMutationFunction, { loading }] = useMutation(
    CREATEPRODUCTS,
    { refetchQueries: [{ query: LISTPRODUCTS }, "ListProducts"] }
  );

  const onClickAdd = async () => {
    const { valid, toastText, toastInfo, newValidations } =
      checkProductFields(product, validations);

    if (!valid) {
      toast.error(toastText, toastInfo);
      setValidations({ ...newValidations });
    } else {
      //Adición de imagen
      if (product.imgBlob != null) {
        const url = await uploadFile(
          `products/${product.name}`,
          product.imgBlob,
          product.imageType
        );
        if (url && url.Location) {
          product.imgURL = url.Location;
          setProduct({ ...product, imgURL: url.Location });
        }
      }

      //Adición de documento
      if (product.documentsBlob != null) {
        const item = await Promise.all(
          product.documentsURL.map(async (doc, index) => {
            return {
              doc,
              result: await uploadFile(
                `products/${doc.name}`,
                product.documentsBlob[index],
                product.documentsType[index]
              ),
            };
          })
        );

        product.documentsURL = [];

        item.map((item) => {
          if (item && item.result) {
            var res = { name: item.doc.name, url: item.result.Location };
            product.documentsURL.push(res);
          }
        });

        setProduct({ ...product });
      }
      addProduct();
    }
  };

  const addProduct = async () => {
    const {
      imgBlob,
      documentsBlob,
      imageType,
      documentsType,
      ...propsProduct
    } = product;

    /* console.log(propsProduct); */

    await createProductMutationFunction({
      variables: {
        input: propsProduct,
      },
    })
      .then((data) => {
        /* console.log(data); */
        toast.success("Máquina pendiente de aprobación", successToast);
        dispatch(startInModal({ inModal: null }));
      })
      .catch((error) => {
        /* console.log(error); */
        toast.error(error.toString(), errorToast);
      });
  };

  return (
    <>
      <ModalTemplate
        title={"Añadir máquina"}
        onClickBack={() => {
          dispatch(startInModal({ inModal: null }));
        }}
      >
        <>
          <ProductForm
            product={product}
            setProduct={setProduct}
            name={"máquina"}
            validations={validations}
            setValidations={setValidations}
          />

          <div className="my-4 bg-white place-content-end flex p-4 rounded-lg shadow-sm">
            <IconTextButton
              icon="done"
              text="Guardar"
              onClickButton={onClickAdd}
              loading={loading}
            />
          </div>
        </>
      </ModalTemplate>
    </>
  );
}

export default AddProduct;
