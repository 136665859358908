import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreateGuard } from "../../../../guards/actions.guard";
import { startInModal } from "../../../../store/tab/thunks";
import IconTextButton from "../../../ui/buttons/IconTextButton";
import Table from "../../../ui/table/Table";

const TabLayout = ({
  titleComponent,
  AddElement,
  EditElement,
  ViewElement,
  nameElement,
  data,
  dataQuery,
  columns,
  // fetchData,
  loading,
  pageCount,
  hideActionButtons,
  hasDownloadBtn,
}) => {
  const dispatch = useDispatch();
  const { inModal } = useSelector((state) => state.tab);

  return (
    <div>
      {inModal === "add" && AddElement && <AddElement />}
      {inModal === "edit" && EditElement && <EditElement />}
      {inModal === "view" && ViewElement && (
        <ViewElement hideActionButtons={hideActionButtons} />
      )}

      <div className="p-2 my-2 md:p-10 md:my-1">
        {data ? (
          <Table
            titleComponent={titleComponent}
            columns={columns}
            data={dataQuery}
            // fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            hasDownloadBtn={hasDownloadBtn}
            nameReport={nameElement}
            topContent={
              !hideActionButtons && (
                <ActionCreateGuard
                  children={
                    <div className="mx-2">
                      <IconTextButton
                        text={`Añadir ${nameElement}`}
                        icon="add"
                        onClickButton={() => {
                          dispatch(startInModal({ inModal: "add" }));
                        }}
                      />
                    </div>
                  }
                />
              )
            }
          />
        ) : (
          <p>No se ha encontrado información.</p>
        )}
      </div>
    </div>
  );
};

export default TabLayout;
