import React from "react";
import checkLength from "../../../../../utils/checkLength";
import { LENGTH_ERROR } from "../../../../admin/pages/users/helpers/inputErrors";
import TextInput from "../../../../ui/Inputs/TextInput";

const ZoneForm = ({ zone, setZone, name, validations, setValidations }) => {
  return (
    <div className="bg-white p-6 flex flex-wrap rounded-lg shadow-sm">
      <div className="basis-3/4">
        <div
          id="info"
          className="grid grid-cols-3   px-4 gap-3 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:gric-cols-3"
        >
          <div className="w-full h-fit">
            <TextInput
              title={`Nombre de ${name}`}
              value={zone.name}
              onChangeValue={(e) => {
                setZone({ ...zone, name: e.target.value });
                setValidations({
                  ...validations,
                  name: checkLength(e.target.value),
                });
              }}
              error={LENGTH_ERROR}
              isValidated={validations.name}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZoneForm;
