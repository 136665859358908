import * as AWS from "aws-sdk";

export default async function deleteFile(url) {

  return new Promise((resolve, reject) => {
    const bucket = new AWS.S3();
    console.log("deleteFile", url);
    bucket.deleteObject(
      {
        Bucket: "az-icon",
        Key: url,
      },
      function (err, data) {
        if (err) {
          console.log("There was an error deleting your file: ", err.message);
          console.log(err);
          reject(err);
        }
        resolve(data);

        console.log(data);
      }
    );
  });
}
