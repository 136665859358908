export const PublicRoutes = {
  LOGIN: "/login",
  RECOVERY_PASSWORD: "/recoveryPassword",
  INSERT_CODE: "/insertCode",
};

export const PrivateRoutes = {
  HOME: "/home",
  ADMIN: "admin",
  USER: "user",
  ZONE: "zone",
  COMPANIES: "companies",
  PRODUCT: "product",
  PRODUCT_APPROVED: "approved",
  REQUEST: "request",
  LOAN: "loan",
  UPLOAD: 'upload',
  UPLOADTOOLS: 'upTools',
  TOOL: 'tool'
};
