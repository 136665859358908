import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import IconTextButton from "../../../../ui/buttons/IconTextButton";
import ModalTemplate from "../../../../ui/modals/ModalTemplate";
import successToast from "../../../../../utils/toast/successToast";
import errorToast from "../../../../../utils/toast/errorToast";
import { startInModal } from "../../../../../store/tab/thunks";
import { toast } from "react-toastify";
import LoanForm from "../layout/LoanForm";
import uploadFile from "../../../../../utils/uploadFile";
import checkMachineryFields from "../helpers/checkLoanFields";
import { LISTLOANS } from "../../../../../graphql/queries/loan/listLoans";
import { CREATELOANS } from "../../../../../graphql/mutations/loan/createLoan";
import checkLoanFields from "../helpers/checkLoanFields";
import LOAN_VALIDATIONS from "../helpers/validations";

function AddLoan() {
  const dispatch = useDispatch();
  const { company, id } = useSelector((state) => state.auth);

  const statusTypes = ["EN PROCESO", "ENTREGADO"];

  const [loan, setLoan] = useState({
    toolName: "",
    toolId: null,
    description: "",
    cellarToolId: null,
    cellarId: null,
    companyId: company.id,
    emails: [],
    createdBy: id,
    creationDate: "",
    finishDate: "",
    status: "EN PROCESO",
    userName: "",
    userNameCreatedBy: "",
  });
  console.log(loan);
  const [validations, setValidations] = useState(LOAN_VALIDATIONS);

  const [createLoanMutationFunction, { loading }] = useMutation(CREATELOANS, {
    refetchQueries: [{ query: LISTLOANS }, "ListLoans"],
  });

  const onClickAdd = async () => {
    const { valid, toastText, toastInfo, newValidations } = checkLoanFields(
      loan,
      validations
    );

    if (!valid) {
      toast.error(toastText, toastInfo);
      setValidations({ ...newValidations });
    } else {
      addLoan();
    }
  };

  const addLoan = async () => {
    console.log("Add loan");
    console.log(loan);

    console.log("final", loan)

    await createLoanMutationFunction({
      variables: {
        input: loan,
      },
    })
      .then((data) => {
        /* console.log(data); */
        toast.success("Creación de préstamo exitoso", successToast);
        dispatch(startInModal({ inModal: null }));
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.toString(), errorToast);
      });
  };

  return (
    <>
      <ModalTemplate
        title={"Añadir préstamo"}
        onClickBack={() => {
          dispatch(startInModal({ inModal: null }));
        }}
      >
        <>
          <LoanForm
            loan={loan}
            setLoan={setLoan}
            name={"préstamo"}
            statusTypes={statusTypes}
            validations={validations}
            setValidations={setValidations}
          />

          <div className="my-4 bg-white place-content-end flex p-4 rounded-lg shadow-sm">
            <IconTextButton
              icon="done"
              text="Guardar"
              onClickButton={onClickAdd}
              loading={loading}
            />
          </div>
        </>
      </ModalTemplate>
    </>
  );
}

export default AddLoan;
