import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startInModal } from "../../../../../store/tab/thunks";
import { useMutation } from "@apollo/client";
import { CREATECELLARS } from "../../../../../graphql/mutations/cellar/createCellar";
import { LISTCELLARS } from "../../../../../graphql/queries/cellar/listCellars";
import IconTextButton from "../../../../ui/buttons/IconTextButton";
import ModalTemplate from "../../../../ui/modals/ModalTemplate";
import successToast from "../../../../../utils/toast/successToast";
import errorToast from "../../../../../utils/toast/errorToast";
import { toast } from "react-toastify";
import ZoneForm from "../layout/ZoneForm";
import ZONE_VALIDATIONS from "../helpers/validations";
import checkZoneFields from "../helpers/checkZoneFields";

function AddZone() {
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.auth);

  const [cellar, setCellar] = useState({
    name: "",
    companyId: company.id,
  });

  const [validations, setValidations] = useState(ZONE_VALIDATIONS);

  const [createCellarMutationFunction, { loading }] = useMutation(
    CREATECELLARS,
    { refetchQueries: [{ query: LISTCELLARS }, "ListCellars"] }
  );

  const onClickAdd = async () => {
    const { valid, toastText, toastInfo, newValidations } = checkZoneFields(
      cellar,
      validations
    );

    if (!valid) {
      toast.error(toastText, toastInfo);
      setValidations({ ...newValidations });
    } else {
      addZone();
    }
  };

  const addZone = async () => {
    await createCellarMutationFunction({
      variables: {
        input: {
          name: cellar.name,
          companyId: cellar.companyId,
        },
      },
    })
      .then(() => {
        toast.success("Sede agregada", successToast);
        dispatch(startInModal({ inModal: null }));
      })
      .catch((error) => {
        toast.error(error.toString(), errorToast);
      });
  };

  return (
    <>
      <ModalTemplate
        title={"Añadir sede"}
        onClickBack={() => {
          dispatch(startInModal({ inModal: null }));
        }}
      >
        <>
          <ZoneForm
            zone={cellar}
            setZone={setCellar}
            name={"sede"}
            validations={validations}
            setValidations={setValidations}
          />

          <div className="my-4 bg-white place-content-end flex p-4 rounded-lg shadow-sm">
            <IconTextButton
              icon="add"
              text="Agregar"
              onClickButton={onClickAdd}
              loading={loading}
            />
          </div>
        </>
      </ModalTemplate>
    </>
  );
}

export default AddZone;
