import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";

const InputFiles = ({ children, id, onChangeFile, index }) => {
  const [isDragging, setDragging] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);

    const files = e.dataTransfer.files;
    const allowedTypes = ["image/jpeg", "image/png"]; // Tipos de archivo permitidos

    // Verificar si se ha seleccionado un archivo no permitido
    const isInvalidFile = Array.from(files).some(
      (file) => !allowedTypes.includes(file.type)
    );

    if (isInvalidFile) {
      toast.error("Debes subir el tipo de archivo permitido.");
      return; // Salir de la función sin procesar los archivos no permitidos
    }

    onChangeFile(files, index);
  };

  return (
    <>
      <div
        className={`${
          isDragging ? "border-blue-500 bg-blue-100" : ""
        } border-dashed border-2 rounded-md p-4`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <input
          accept="image/*"
          type="file"
          id={id ? id : "id"}
          style={{ display: "none" }}
          multiple
          onChange={(e) => onChangeFile(e.target.files, index)}
        />
        <label className="flex justify-center" htmlFor={id ? id : "id"}>{children}</label>
      </div>
    </>
  );
};

export default InputFiles;
