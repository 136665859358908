import { createSlice } from "@reduxjs/toolkit";

export const persistUserState = ({
  status,
  id,
  name,
  roleId,
  imgURL,
  company,
  cellarId,
  role,
  disabled,
  actions,
}) => {
  localStorage.setItem(
    "dataUser",
    JSON.stringify({
      status,
      id,
      name,
      roleId,
      imgURL,
      company,
      cellarId,
      role,
      disabled,
      actions,
    })
  );
};

export const clearUserState = () => {
  localStorage.removeItem("dataUser");
};

export const authSlice = createSlice({
  name: "auth",
  initialState: localStorage.getItem("dataUser")
    ? JSON.parse(localStorage.getItem("dataUser"))
    : {
        status: "not-authenticated" /* authenticated or not-authenticated */,
        id: null,
        name: null,
        roleId: null,
        imgURL: null,
        company: null,
        cellarId: null,
        actions: null,
        disabled: null,
        role: null,
      },
  reducers: {
    login: (state, { payload }) => {
      state.status = "authenticated";
      state.id = payload.id;
      state.name = payload.name;
      state.roleId = payload.roleId;
      state.imgURL = payload.imgURL;
      state.company = payload.company;
      state.cellarId = payload.cellarId;
      state.role = payload.role;
      state.disabled = payload.disabled;
      state.actions = payload.actions;

      persistUserState({
        status: state.status,
        id: state.id,
        name: state.name,
        roleId: state.roleId,
        imgURL: state.imgURL,
        company: state.company,
        cellarId: state.cellarId,
        role: state.role,
        disabled: state.disabled,
        actions: state.actions,
      });
    },
    logout: (state, payload) => {
      state.status = "not-authenticated";
      state.id = null;
      state.name = null;
      state.roleId = null;
      state.imgURL = null;
      state.company = null;
      state.cellarId = null;
      state.role = null;
      state.disabled = null;
      state.actions = null;

      clearUserState();
    },
    checkingCredentials: (state) => {
      state.status = "checking";
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, logout, checkingCredentials } = authSlice.actions;
