export const downloadToCSV = (headers, data, nameReport) => {
  console.log("download!!!", data)
  const rows = [];

  const headerRows = [];
  const headerIndex = {};
  var ind = 0;

  headers.map((item, index) => {
    if (item.Header !== "Acción" && item.Header !== "Foto") {
      headerRows.push(item.Header);
      headerIndex[item.Header] = ind;
      ind++;
    }
  });
  rows.push(headerRows);

  data.map((item) => {
    const newRow = [];
   // console.error("item", item)
    item.cells.map((cell) => {
    //  console.error("cell", cell)
      if (headerIndex[cell.column.Header] != null) {
        newRow[headerIndex[cell.column.Header]] = cell.value;
      }
    });
    rows.push(newRow);
  });

  /* console.log("finalRows", rows); */

  var csvContent = "data:text/csv;charset=utf-8,";
  /* add the column delimiter as comma(,) and each row splitted by new line character (\n) */
  rows.forEach(function (rowArray) {
    let row = rowArray.join(",");
    csvContent += row + "\r\n";
  });

  /* create a hidden <a> DOM node and set its download attribute */
  var encodedUri = encodeURI(csvContent);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${nameReport}_reporte.csv`);
  document.body.appendChild(link);
  /* download the data file named "Stock_Price_Report.csv" */
  link.click();
};
