import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { UPDATEUSERS } from "../../../../../graphql/mutations/auth/updateUsers";
import { LISTUSERS } from "../../../../../graphql/queries/user/listUsers";
import { startInModal } from "../../../../../store/tab/thunks";
import uploadFile from "../../../../../utils/uploadFile";
import IconTextButton from "../../../../ui/buttons/IconTextButton";
import ModalTemplate from "../../../../ui/modals/ModalTemplate";
import checkUserFields from "../helpers/checkUserFields";
import USER_VALIDATIONS from "../helpers/userValidations";
import UserForm from "./UserForm";
import { useMutation } from "@apollo/client";
import successToast from "../../../../../utils/toast/successToast";
import { deleteTypename } from "../../../../../utils/deleteTypename";
import errorToast from "../../../../../utils/toast/errorToast";
import { logout } from "../../../../../store/auth/authSlice";
import { PublicRoutes } from "../../../../../router/routes";
import { useNavigate } from "react-router-dom";

function EditUser() {
  const dispatch = useDispatch();
  const { selectedElement } = useSelector((state) => state.tab);

  const { id } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const [user, setUser] = useState(selectedElement.row);
  const [validations, setValidations] = useState(USER_VALIDATIONS);

  const [updateUserMutationFunction] = useMutation(UPDATEUSERS, {
    refetchQueries: [{ query: LISTUSERS }, "ListUsers"],
  });

  const onClickEdit = async () => {
    const noCheck = {
      password: true,
      email: true,
    };

    const { valid, toastText, toastInfo, newValidations } = checkUserFields(
      user,
      validations,
      noCheck
    );

    var validSelect = true;

    if (user.roleId == null) {
      validSelect = false;
    }
    if (user.cellarId == null && user.roleId && user.roleId !== "admin") {
      validSelect = false;
    }

    if (!valid) {
      toast.error(toastText, toastInfo);
      setValidations({ ...newValidations });
    } else {
      if (!validSelect) {
        const resp = {
          toastText: "ERROR: Verifica la selección de sede o rol.",
          toastInfo: {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        };
        toast.error(resp.toastText, resp.toastInfo);
      } else {
        if (user.imgBlob != null) {
          const url = await uploadFile(
            `products/${user.id}`,
            user.imgBlob,
            user.imageType
          );
          /* console.log("url", url); */
          if (url && url.Location) {
            user.imgURL = url.Location;
            setUser({ ...user, imgURL: url.Location });
          }
        }
        editUser();
      }
    }
  };

  const editUser = () => {
    const {
      imgBlob,
      imageType,
      company,
      config,
      creationDate,
      role,
      cellar,
      ...propsUser
    } = user;

    const newProps = deleteTypename(propsUser);
    newProps.companyId = company.id;

    updateUserMutationFunction({
      variables: {
        user: newProps,
      },
    })
      .then((data) => {
        const res = data.data.updateUser;

        toast.success("Usuario actualizado.", successToast);
        dispatch(startInModal({ inModal: null }));

        //si el usuario actualizado tiene el mismo id del que inició sesión
        if (id === res.id) {
          console.log("Son iguales los emails");
          console.log(id, res.id);

          //Mostrar el toast de cierre de sesión
          toast.error("Se cerrará la sesión por seguridad.", errorToast);

          //Retrasar el cierre de sesión por 3 segundos
          setTimeout(() => {
            dispatch(logout());
            navigate(PublicRoutes.LOGIN, {
              replace: true,
            });
          }, 3000); //3 segundos
        } else {
          console.log("No son iguales");
          console.log(id, res.id);
        }
      })
      .catch((error) => {
        /*  console.log(error); */
      });
  };

  return (
    <>
      <ModalTemplate
        title={"Editar usuario"}
        onClickBack={() => {
          dispatch(startInModal({ inModal: null }));
        }}
      >
        <>
          <UserForm
            user={user}
            setUser={setUser}
            validations={validations}
            setValidations={setValidations}
            hasPassword={false}
            hasEmail={false}
            hasEnabledUser={true}
          />

          <div className="my-4 bg-white place-content-end flex p-4 rounded-lg shadow-sm">
            <IconTextButton
              icon="done"
              text="Guardar"
              onClickButton={onClickEdit}
            />
          </div>
        </>
      </ModalTemplate>
    </>
  );
}

export default EditUser;
