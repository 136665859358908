import { useSelector } from "react-redux";
import NavlinkItem from "../components/ui/sidebar/NavlinkItem";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const adminActions = {
  canRead: [
    "User",
    "Product",
    "Request",
    "Service",
    "Zone",
    "ProductApproved",
    "Loan",
    "Upload",
    "UploadTools",
    "Tool",
  ],
  canCreate: [
    "User",
    "Product",
    "Request",
    "Service",
    "Zone",
    "ProductApproved",
    "Loan",
    "Upload",
    "UploadTools",
    "Tool",
  ],
  canUpdate: [
    "User",
    "Product",
    "Request",
    "Service",
    "Zone",
    "ProductApproved",
    "Loan",
    "Upload",
    "UploadTools",
    "Tool",
  ],
};
/* invitado o tecnico */
export const guestActions = {
  canRead: ["Product", "Request", "Service", "Loan", "Tool"],
  canCreate: ["Request"],
  canUpdate: ["Request"],
};
/* admin sede */
export const operatorActions = {
  canRead: ["User", "Product", "Request", "Service", "Tool", "Loan"],
  canCreate: ["User", "Product", "Request", "Service", "Tool", "Loan"],
  canUpdate: ["User", "Product", "Request", "Service", "Tool", "Loan"],
};
/* proveedor */
export const providerActions = {
  canRead: ["Request", "Service"],
  canCreate: ["Request", "Service"],
  canUpdate: ["Request", "Service"],
};

//------------ACTIONS-------------------------------
/* Si se visibiliza el botón de agregar */
export const ActionCreateGuard = ({ children }) => {
  const { actions } = useSelector((state) => state.auth);
  const { component } = useSelector((state) => state.tab);

  if (actions.canCreate.includes(component)) {
    return children;
  }
};

/* si se visibiliza el botón de editar */
export const ActionUpdateGuard = ({ children }) => {
  const { actions } = useSelector((state) => state.auth);
  const { component } = useSelector((state) => state.tab);

  if (actions.canUpdate.includes(component)) {
    return children;
  }
};

/* Si dentro de las acciones del rol viene el componentName de cada item del sidebar entonces mostrarlo */
export const ActionViewItemOnSidebarGuard = ({
  route,
  icon,
  name,
  componentName,
  isActive,
}) => {
  const { actions } = useSelector((state) => state.auth);

  return (
    actions.canRead.includes(componentName) && (
      <NavlinkItem route={route} icon={icon} name={name} isActive={isActive} />
    )
  );
};
