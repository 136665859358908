import { createContext, useContext, useState } from "react";

export const UsersContext = createContext();

export const UsersProvider = ({ children }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [inModal, setInModal] = useState(null);
  /* console.log(selectedUser) */
  return (
    <UsersContext.Provider
      value={{
        selectedUser,
        setSelectedUser,
        inModal,
        setInModal,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export const useUsersContext = () => {
  const context = useContext(UsersContext);
  if (context === undefined) {
    throw new Error("UsersContext must be used within a UsersProvider");
  }
  return context;
};
